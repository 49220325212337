import React, {useState} from 'react';
import FormField from "../../components/BaseComponents";

const EditRow = ({row = {}, columns, error = {}, actions}) => {
    const [form, setForm] = useState(row);

    const onChange = (e, {name, value}) => {
        setForm(form => ({
            ...form,
            [name] : value
        }));
    };

    return (
        <>
            {
                (columns || []).map((column, i) => (
                    <td style={{borderLeft: i === 0 && '0'}} className='ui form table-edit-fields' onClick={e => e.stopPropagation()}>
                        {column.isReadOnly ? row[column.name] : <FormField
                            {...column}
                            fieldClassName={column.name === 'noticeBody' ? 'code-field' : ''}
                            noScrollColumn={column}
                            key={column.name}
                            error={error[column.name]}
                            value={form[column.name]}
                            onChange={onChange}
                            noLabel
                        />}
                    </td>
                ))
            }
            {
                actions && actions(form)
            }
        </>
    );
};

export default EditRow;