import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { securityCasesSelector, progressSelector } from '../../../../ducks/securityCases';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { dateToUTC } from '../../../../utils/dateTimeFormater';
import { xorWith } from 'lodash';

const SecurityCases = () => {
    const { t } = useTranslation();

    const securityCases = useSelector(state => securityCasesSelector(state));

    const loading = useSelector(state => progressSelector(state));

    console.log(JSON.stringify(securityCases));
    return (
        <div className="tabs-card tabs-card_history">
            <Grid>
                {(securityCases || []).map((x, i) => (
                    <Grid.Row key={i}>
                        <Grid.Column width={5}>
                            <div>{dateToUTC(x.sendDateTime, 'DD.MM.YYYY HH:mm')}</div>
                            <div className="history-who">{t(x.caseStatusTitle)}</div>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <div className="history-comment"> {x.sbRequest} </div>
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        </div>
    );
};

export default SecurityCases;
