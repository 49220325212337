export const options = [
    {key: '25', value: '25', text: '25'},
    {key: '50', value: '50', text: '50'},
    {key: '100', value: '100', text: '100'},
];

export const optionsNumberOfTc = [
    {key: '3', value: '3', text: '3'},
    {key: '4', value: '4', text: '4'},
    {key: '5', value: '5', text: '5'},
];