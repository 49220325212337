import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Grid, Table, Icon, Segment, Dropdown, Pagination, Modal} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import Block from '../../components/CardLayout/components/block';
import {
    fmContactsColumns,
    PERSONAL_CABINET_CONTACTS_FM,
} from "../../constants/personalCabinetPages";
import FormField from "../../components/BaseComponents";
import {BIG_TEXT_TYPE} from "../../constants/columnTypes";
import {
    errorSelector,
    feedbackLoadingSelector,
    getFmContacts,
    listSelector,
    progressSelector,
    sendFeedback,
    setFeedbackErrors,
    totalCountSelector
} from "../../ducks/personalCabinet";
import TableInfo from "../../components/TableInfo";
import MultiSelect from "../../components/BaseComponents/MultiSelect";

const ContactsFM = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation();

    let [params, setParams] = useState({});
    let [paramsOpen, setParamsOpen] = useState(true);
    let [click, setClick] = useState(false);
    let [openModal, setOpenModal] = useState(false);
    let [trigger, setTrigger] = useState(false);
    let [commentValue, setCommentValue] = useState('');

    let [height, setHeight] = useState(0);

    useEffect(() => {
        let el = document.getElementById('params');
        if (el) {
            setHeight(el.getBoundingClientRect().height);
        }
    }, [params, paramsOpen]);

    useEffect(() => {
        let els = click && document.getElementsByClassName('scroll-table-container');
        if (els && els[0]) els[0].style.maxHeight = `calc(100vh - ${height+160}px)`;
    }, [height, click]);

    const totalCount = useSelector(totalCountSelector);
    const loading = useSelector(progressSelector);
    const loadingFeedback = useSelector(feedbackLoadingSelector);
    const list = useSelector(listSelector);
    const error = useSelector(errorSelector);

    const load = (data) => {
        dispatch(getFmContacts({...data, ...params}));
    };

    const handleChangeParams = (e, {name, value}) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        if (name === 'detalizationType' && params.detalizationType !== value) newParams['detalizationTypeValue'] = null;
        setParams(newParams);
    };

    const item = {
        menuItem: PERSONAL_CABINET_CONTACTS_FM,
        render: () => (
            click && <div className='report__table-info'>
                <TableInfo
                    headerRow={fmContactsColumns}
                    topBlockOff
                    loading={loading}
                    list={list}
                    totalCount={totalCount}
                    loadList={load}
                    triggerLoad={trigger}
                />
            </div>
        ),
    };

    const saveFeedback = () => {
        dispatch(sendFeedback({
                comment: commentValue,
                callback: onCloseModal
            })
        );
    }
    const onCloseModal = () => {
        setOpenModal(false);
        setCommentValue('');
        error && dispatch(setFeedbackErrors());
    }

    return (
        <div className="container personal-cabinet__page">
            <div className="report">
                <div className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'}/>
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form>
                        <Button className='report_params__btn'
                                onClick={() => setOpenModal(true)}>{t('feedbackBtn')}</Button>
                        <Grid>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <MultiSelect
                                        name="subsidiaryId"
                                        source="subsidiaries"
                                        value={params.subsidiaryId}
                                        onChange={handleChangeParams}
                                        textValue={(!params.subsidiaryId || params.subsidiaryId.length === 0) && t('All')}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name='departmentId'
                                        source='departments'
                                        value={params.departmentId}
                                        onChange={handleChangeParams}
                                        textValue={(!params.departmentId || params.departmentId.length === 0) && t('All')}
                                    />
                                </Grid.Column>
                                <Grid.Column className=''>
                                    <Button className='report_button' primary
                                            disabled={loading}
                                            onClick={() => {
                                                !click && setClick(true);
                                                setTrigger(trigger => !trigger)
                                            }}>
                                        {t('showContacts')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <div className={`report_table ${!paramsOpen ? 'report_table_max' : ''}`}>
                    <Block
                        item={item}
                        loading={loading}
                    />
                </div>
            </div>

            <Modal open={openModal} size="tiny" closeOnDimmerClick={false}>
                <Modal.Header>
                    {t('feedbackBtn')}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <FormField
                                error={error}
                                type={BIG_TEXT_TYPE}
                                name='commentFeedback'
                                width={'100%'}
                                autoFocus value={commentValue}
                                onChange={(e, {value}) => {
                                    error && dispatch(setFeedbackErrors());
                                    setCommentValue(value);
                                }}
                            />
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCloseModal}>{t('cancelConfirm')}</Button>
                    <Button
                        loading={loadingFeedback}
                        color="primary"
                        disabled={!commentValue || commentValue === '' || loadingFeedback}
                        onClick={saveFeedback}
                    >
                        {t('SendButton')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default ContactsFM;

