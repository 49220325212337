import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deliveryTypeModalSelector,
    errorSelector,
    saveDeliveryType,
    saveLoadingSelector,
    setDeliveryTypeModal,
} from "../../../../ducks/gridCard";
import {ENUM_TYPE} from "../../../../constants/columnTypes";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {useTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";

const DeliveryTypeModal = ({callbackSuccess}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const deliveryTypeModal = useSelector(state => deliveryTypeModalSelector(state));
    const loading = useSelector(state => saveLoadingSelector(state));
    const error = useSelector(state => errorSelector(state));

    const save = (data) => {
        dispatch(saveDeliveryType({...data, id: deliveryTypeModal.ids[0], deliveryType: data.form.deliveryType}));
    };

    const onClose = () => {
        dispatch(setDeliveryTypeModal({open: false}));
        callbackSuccess && callbackSuccess();
    }

    return (
        <RequiredFieldsModal
            open={deliveryTypeModal.open}
            id={null}
            onClose={onClose}
            error={error}
            title={t('shippingDeliveryTypeSelect')}
            fields={[
                {
                    isRequired: true,
                    name: "deliveryType",
                    text: "shippingDeliveryType",
                    source: "shippingDeliveryType",
                    type: ENUM_TYPE
                }
            ]}
            saveRequest={save}
            saveBtnName={t('SendButton')}
            loading={loading}
            btns={[
                <Button onClick={onClose}>
                    {t('CancelButton')}
                </Button>
            ]}
        />
    );
};

export default DeliveryTypeModal;