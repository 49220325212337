import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    BIG_TEXT_TYPE,
    BOOLEAN_TYPE,
    CHECKBOX_TYPE,
    DATE_TIME_TYPE,
    DATE_TYPE,
    ENUM_TYPE,
    LOCAL_DATE_TIME,
    NUMBER_TYPE,
    SELECT_TYPE,
    SOLD_TO_TYPE,
    STATE_TYPE,
    TEXT_TYPE,
    TIME_TYPE,
    PASSWORD_TYPE,
    INTEGER_TYPE,
    RETURN_COST_TYPE,
    MULTISELECT_TYPE,
    PHONE_NUMBER_TYPE,
    EMAILS_TYPE,
    HIDDEN_TYPE,
    MONEY_TYPE,
    RADIO_TYPE,
    INN_TYPE,
    EMAIL_TYPE,
    LINKS_TYPE,
    MULTI_ENUM_TYPE,
    TIME_ZONE_TYPE,
    PERCENT_TYPE,
    DATES_TYPE,
    CODE_TYPE,
    DATES_OR_PERIOD_TYPE,
} from "../../constants/columnTypes";
import Text from "./Text";
import TextArea from "./TextArea";
import State from "./State";
import Date from "./Date";
import Select from "./Select_new";
import MultiSelect from "./MultiSelect";
import Bool from "./Bool";
import DateTime from "./DateTime";
import {
    SETTINGS_TYPE_HIDE,
    SETTINGS_TYPE_SHOW,
} from "../../constants/formTypes";
import CheckBox from "./Checkbox";
import { clearError } from "../../ducks/gridCard";
import SoldToField from "./SoldToField";
import PasswordField from "./Password";
import Number from "./Number";
import ReturnCosts from "./ReturnCosts";
import PhoneNumber from "./PhoneNumber";
import AdditionalMultiSelect from "./AdditionalMultiSelect";
import LocalDateTime from "./LocalDateTime";
import RadioButtons from "./RadioButtons";
import Inn from "./Inn";
import Links from "./Links";
import Dates from "./Dates";
import Code from "./Code";
import DateOrPeriod from "./DateOrPeriod";

const getTypeFacet = {
    [TEXT_TYPE]: <Text />,
    [STATE_TYPE]: <State />,
    [DATE_TYPE]: <Date />,
    [DATE_TIME_TYPE]: <DateTime />,
    [LOCAL_DATE_TIME]: <LocalDateTime />,
    [TIME_TYPE]: <Text />,
    [SELECT_TYPE]: <Select />,
    [MULTISELECT_TYPE]: <MultiSelect />,
    [MULTI_ENUM_TYPE]: <MultiSelect isTranslate />,
    [NUMBER_TYPE]: <Number />,
    [BOOLEAN_TYPE]: <Bool />,
    [ENUM_TYPE]: <Select isTranslate />,
    [BIG_TEXT_TYPE]: <TextArea />,
    [CHECKBOX_TYPE]: <CheckBox />,
    [SOLD_TO_TYPE]: <SoldToField />,
    [RETURN_COST_TYPE]: <ReturnCosts />,
    [PASSWORD_TYPE]: <PasswordField />,
    [MONEY_TYPE]: <Number isMoney />,
    [PERCENT_TYPE]: <Number isPercent />,
    [INTEGER_TYPE]: <Number isInteger />,
    [PHONE_NUMBER_TYPE]: <PhoneNumber />,
    [EMAILS_TYPE]: <AdditionalMultiSelect />,
    [RADIO_TYPE]: <RadioButtons />,
    [INN_TYPE]: <Inn />,
    [LINKS_TYPE]: <Links />,
    [TIME_ZONE_TYPE]: <Select />,
    [DATES_TYPE]: <Dates />,
    [DATES_OR_PERIOD_TYPE]: <DateOrPeriod multiDates />,
    [CODE_TYPE]: <Code />,
};

const FormField = (props) => {
    const dispatch = useDispatch();

    let params = {
        ...props,
        type: props.typeValue,
        key: props.name,
    };

    if (!props.text && props.displayNameKey) {
        params = {
            ...params,
            text: props.displayNameKey,
        };
    }

    if (props.type === TIME_TYPE) {
        params = {
            ...params,
            type: "time",
        };
    }

    if (
        !props.type ||
        props.type === TEXT_TYPE ||
        props.type === INTEGER_TYPE ||
        props.type === NUMBER_TYPE ||
        props.type === MONEY_TYPE ||
        props.type === EMAIL_TYPE
    ) {
        params = {
            ...params,
            value:
                params.value === undefined || params.value === null
                    ? ""
                    : params.value,
        };
    }

    if (props.type === EMAIL_TYPE) {
        params = {
            ...params,
            type: "email",
        };
    }

    if (
        (props.settings && props.settings === SETTINGS_TYPE_SHOW) ||
        props.isReadOnly
    ) {
        params = {
            ...params,
            isDisabled: true,
        };
    }

    if (props.settings && props.settings === SETTINGS_TYPE_HIDE) {
        params = {
            ...params,
            isDisabled: true,
            value: null,
        };
    }

    useEffect(() => {
        if (props.error) {
            dispatch(clearError && clearError(props.name));
        }
    }, [props.value]);

    /* switch (props.type || (props.column && props.column.type)) {
         case TEXT_TYPE:
             return <Text {...params} />;
         case STATE_TYPE:
             return <State {...params} />;
         case DATE_TYPE:
             return <Date {...params} />;
         case DATE_TIME_TYPE:
             return <DateTime {...params} />;
         case TIME_TYPE:
             return <Text type="time" {...params} />;
         case SELECT_TYPE:
             return <Select {...params} />;
         case NUMBER_TYPE:
             return <Text {...params} />;
         case BOOLEAN_TYPE:
             return <Bool {...params} />;
         case ENUM_TYPE:
             return <Select isTranslate {...params} />;
         case BIG_TEXT_TYPE:
             return <TextArea {...params} />;
         case CHECKBOX_TYPE:
             return <CheckBox {...params} />;
         default:
             return <Text {...params} />
     } */

    return !props.isHidden
        ? React.cloneElement(getTypeFacet[props.type] || <Text />, {
              ...params,
          })
        : null;
};

export default React.memo(FormField);
