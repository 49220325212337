import {createSelector} from "reselect";
import {postman} from "../utils/postman";
import {all, call, put, takeEvery} from "redux-saga/effects";

//*  TYPES  *//

const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST';
const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
const GET_BANNERS_ERROR = 'GET_BANNERS_ERROR';

const BANNER_CLICK_REQUEST = 'BANNER_CLICK_REQUEST';
const BANNER_CLICK_SUCCESS = 'BANNER_CLICK_SUCCESS';
const BANNER_CLICK_ERROR = 'BANNER_CLICK_ERROR';

const BANNER_CLOSE_REQUEST = 'BANNER_CLOSE_REQUEST';
const BANNER_CLOSE_SUCCESS = 'BANNER_CLOSE_SUCCESS';
const BANNER_CLOSE_ERROR = 'BANNER_CLOSE_ERROR';

//*  INITIAL STATE  *//

const initial = {
    bannersList: [],
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_BANNERS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                progress: false,
                bannersList: payload
            };
        case GET_BANNERS_ERROR:
            return {
                ...state,
                progress: false
            };
        case BANNER_CLICK_REQUEST:
            return {
                ...state,
                progress: true
            };
        case BANNER_CLICK_SUCCESS:
        case BANNER_CLICK_ERROR:
            return {
                ...state,
                progress: false
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getBannersRequest = payload => {
    return {
        type: GET_BANNERS_REQUEST,
        payload,
    };
};

export const clickBannerRequest = payload => {
    return {
        type: BANNER_CLICK_REQUEST,
        payload,
    };
};

export const closeBannerRequest = payload => {
    return {
        type: BANNER_CLOSE_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.banners;
export const progressSelector = createSelector(stateSelector, state => state.progress);
export const bannersSelector = createSelector(stateSelector, state => state.bannersList);

//*  SAGA  *//

export function* getBannersSaga() {
    try {
        const result = yield postman.get('banners/view');

        yield put({ type: GET_BANNERS_SUCCESS, payload: result });
    } catch (error) {
        yield put({ type: GET_BANNERS_ERROR });
    }
}

export function* clickBannerSaga({payload}) {
    try {
        const {id, callback} = payload;
        yield postman.post(`banners/${id}/click`);
        callback && callback();
        yield put({ type: BANNER_CLICK_SUCCESS});
    } catch (error) {
        yield put({ type: BANNER_CLICK_ERROR });
    }
}

export function* closeBannerSaga({payload}) {
    try {
        const {id, callback} = payload;
        yield postman.post(`banners/${id}/close`);
        callback && callback();
        yield put({ type: BANNER_CLOSE_SUCCESS});
    } catch (error) {
        yield put({ type: BANNER_CLOSE_ERROR });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_BANNERS_REQUEST, getBannersSaga),
        takeEvery(BANNER_CLICK_REQUEST, clickBannerSaga),
        takeEvery(BANNER_CLOSE_REQUEST, closeBannerSaga),
    ]);
}

