import React from 'react';
import {Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const ActionsTableCell = ({actions, row, deleteRow, load, isEdit, toTop}) => {
    const { t } = useTranslation();
    return (
        <Table.Cell onClick={e => e.stopPropagation()} textAlign="center">
            {actions &&
                actions(row, load, t, deleteRow, isEdit, toTop).map(
                    (action, index) => (
                        <React.Fragment
                            key={`action_${index}`}
                        >
                            {action}
                        </React.Fragment>
                    ),
                )}
        </Table.Cell>
    );
};

export default ActionsTableCell;