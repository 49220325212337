import React, { useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { formatDate, parseUtcDateTimeToDate } from '../../../../utils/dateTimeFormater';
import { assignAuctionWinnerRequest } from '../../../../ducks/gridCard';
import { useDispatch } from 'react-redux';

const AuctionPriceOffers = ({ items, cardId, shippingVersion, isSelectButtonsVisible, load }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [openModal, setOpenModal] = useState(false);

    const handleSave = priceOfferId => {
        dispatch(
            assignAuctionWinnerRequest({
                priceOfferId,
                cardId,
                shippingVersion,
                callbackSuccess: load,
            }),
        );
    };

    return (
        <div className="tabs-card">
            <Table className="wider container-margin-top-bottom">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell key={'transportCompanyId'}>
                            {t('transportCompanyId')}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={'responsiblePhoneNumber'}>
                            {t('responsiblePhoneNumber')}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={'securityServiceDenyPercent'}>
                            {t('securityServiceDenyPercent')}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={'offerAmount'}>
                            {t('PriceOffers.OfferAmount')}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={'creationDate'}>
                            {t('PriceOffers.CreatedAt')}
                        </Table.HeaderCell>
                        {isSelectButtonsVisible && <Table.HeaderCell />}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map(item => (
                        <Table.Row key={item.id}>
                            <Table.Cell key={`${item.id}_transportCompanyId`}>
                                {item['transportCompanyId'] && item['transportCompanyId'].name}
                            </Table.Cell>

                            <Table.Cell key={`${item.id}_responsiblePhoneNumber`}>
                                {item['responsiblePhoneNumber']}
                            </Table.Cell>

                            <Table.Cell key={`${item.id}_securityServiceDenyPercent`}>
                                {item['securityServiceDenyPercent']}
                            </Table.Cell>

                            <Table.Cell key={`${item.id}_offerAmount`}>
                                {item['offerAmount']}
                            </Table.Cell>

                            <Table.Cell key={`${item.id}_creationDate`}>
                                {item['creationDate'] &&
                                    formatDate(
                                        parseUtcDateTimeToDate(item['creationDate']),
                                        'dd.MM.yyyy HH:mm',
                                    )}
                            </Table.Cell>

                            {isSelectButtonsVisible && (
                                <Table.Cell>
                                    <Modal
                                        trigger={<Button primary>{t('Choose')}</Button>}
                                        open={openModal.open}
                                        onOpen={() => {
                                            setOpenModal({
                                                ...item,
                                                open: true
                                            })
                                        }}
                                        onClose={() => setOpenModal({open: false})}
                                    >
                                        <Modal.Header>{t('ChooseWinner')}</Modal.Header>
                                        <Modal.Content>
                                            <div>
                                                {`${t('Case_TransportCompanyId')}: `}

                                                <b style={{ paddingLeft: '8px' }}>
                                                    {openModal['transportCompanyId'] &&
                                                    openModal['transportCompanyId'].name}
                                                </b>
                                                <br />
                                                {`${t('PriceOffers.OfferAmount')}: `}

                                                <b style={{ paddingLeft: '8px' }}>
                                                    {openModal['offerAmount']}
                                                </b>
                                                <br />
                                                <br />
                                            </div>
                                            <span dangerouslySetInnerHTML={{ __html: t('confirm_choose_tk', {
                                                company:
                                                    openModal['transportCompanyId'] &&
                                                    openModal['transportCompanyId'].name,
                                            })} } />
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button onClick={() => setOpenModal({open: false})}>
                                                {t('CancelButton')}
                                            </Button>
                                            <Button primary onClick={() => handleSave(openModal.id)}>
                                                {t('SaveButton')}
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Table.Cell>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default AuctionPriceOffers;
