import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Grid, Popup} from 'semantic-ui-react';
import FormField from "../formField";
import {BIG_TEXT_TYPE, MULTISELECT_TYPE, SELECT_TYPE} from "../../../../constants/columnTypes";
import AnomalyPhotos from "./anomalyPhotos";
import {useDispatch, useSelector} from "react-redux";
import {anomaliesLoadingSelector, loadingSaveSelector, newAnomaly} from "../../../../ducks/gridCard";

const Anomaly = ({id, typeName, anomaly, settings, columnsConfig, error, key, callbackSuccess, onDeleteAnomaly, disabled, isDelivery}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [row, setRow] = useState({...anomaly});
    const [changed, setChanged] = useState(false);
    const [sourceParams, setSourceParams] = useState(null);

    const loadingSave = useSelector(state => loadingSaveSelector(state));
    const loading = useSelector(state => anomaliesLoadingSelector(state));

    const notDropdownTypes = [
        'refundSurplus',
        'notLoading',
        'notUnloading',
        '5', //refundSurplus
        '3', //notLoading
        '16' //notUnloading
    ];

    useEffect(() => {
        anomaly && setRow(anomaly);
        setChanged(false);
    }, [anomaly]);

    useEffect(() => {
        !loading && id && setSourceParams({id});
    }, [loading, id]);

    const saveAnomaly = () => {
        dispatch(newAnomaly({
            id: id,
            data: row,
            type: typeName,
            callbackSuccess: (id) => {
                callbackSuccess(id);
                setChanged(false);
            }
        }));
    }

    const onChangeValue = (e, {value, name}) => {
        setRow(row => {
            let data = {};
            if (typeName === "stopperAnomalyType" && name === 'anomalyType') {
                if (clientIsText(row) || (value && value.value && notDropdownTypes.includes(value.value))) data.anomalyClients = [];
            }
            return {
                ...row,
                [name]: value,
                ...data
            }
        });
        setChanged(true);
    };

    const disabledSave = (row) => {
        return !row.anomalyType || (typeName !== 'shippingAnomalyType' && !clientIsText(row) && (!row.anomalyClients || row.anomalyClients === '' || !row.anomalyClients.length)) || !row.anomalyComment || row.anomalyComment === '' || loadingSave;
    }

    const clientIsText = (row) =>
        typeName === "stopperAnomalyType" && row.anomalyType && notDropdownTypes.includes(row.anomalyType.value);

    return (
        sourceParams && <Grid.Row key={key}>
            <Grid.Column width={4}>
                <FormField
                    isTranslate
                    name={'anomalyType'}
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={row}
                    error={error}
                    onChange={onChangeValue}
                    type={SELECT_TYPE}
                    source={typeName === 'shippingAnomalyType' ? typeName : `${typeName}/${isDelivery ? 'unloading' : 'loading'}`}
                    isRequired
                    isDisabled={disabled}
                />
            </Grid.Column>
            <Grid.Column width={4}>
                <FormField
                    name="anomalyClients"
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={row}
                    error={error}
                    onChange={onChangeValue}
                    type={MULTISELECT_TYPE}
                    source={typeName === 'shippingAnomalyType' ? 'anomalyClient/shipping' : 'anomalyClient/stopper'}
                    isRequired={typeName !== 'shippingAnomalyType' && !clientIsText(row)}
                    maxLength={1000}
                    sourceParams={sourceParams}
                    allowAdditions={clientIsText(row)}
                    clearable={clientIsText(row)}
                    isDisabled={disabled}
                />
            </Grid.Column>
            <Grid.Column width={5}>
                <FormField
                    name="anomalyComment"
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={row}
                    error={error}
                    onChange={onChangeValue}
                    type={BIG_TEXT_TYPE}
                    isRequired
                    maxLength={1000}
                    isDisabled={disabled}
                />
            </Grid.Column>
            <Grid.Column width={3}>
                <Form.Field>
                    <label><br/></label>
                    <div style={{display: 'flex'}}>
                        <AnomalyPhotos
                            id={row.id}
                            disabled={row.isNew}
                            disabledAdd={disabled}
                        />
                        {(row.isNew || changed) && <Popup
                            content={t('save')}
                            position="bottom right"
                            trigger={
                                <Button icon='save' loading={loadingSave} onClick={() => saveAnomaly()} disabled={disabledSave(row)}/>
                            }
                        />}

                        {!disabled && <Popup
                            content={t('delete')}
                            position="bottom right"
                            trigger={
                                <Button icon='delete' onClick={onDeleteAnomaly}/>
                            }
                        />}
                    </div>
                </Form.Field>
            </Grid.Column>
        </Grid.Row>
    )
};

export default Anomaly;
