import React, { useEffect } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

// Fix for default markers
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const colors = {
    blue: '#4285f4',
    orange: '#fbbc05',
    green: '#34a853',
    teal: "#15A6CA"
};

const LeafletMap = ({mapData, setShipping, routeData, setRouteData, isTransportCompanyUser}) => {
    const mapState = {
        center: [52.1157, 19.4013],
        zoom: 7,
    };

    const stopperByType = (mD) => {
        if (mD.stoppers && mD.stoppers.length) {
            switch (mD.pointType) {
                case 0:
                case 1:
                    return mD.stoppers[0];
                case 2:
                    return mD.stoppers[mD.stoppers.length - 1];
                default:
                    return mD.stoppers[0];
            }
        }
    };

    const colorByType = (mD) => {
        switch (mD.pointType) {
            case 0:
            case 1:
                return colors.blue;
            case 2:
                return colors.orange;
            default:
                return colors.blue;
        }
    };

    const createCustomIcon = (color) => {
        return L.divIcon({
            className: 'custom-div-icon',
            html: `<div style="background-color: ${color}; width: 10px; height: 10px; border-radius: 50%; border: 2px solid white; box-shadow: 0 0 0 2px ${color};"></div>`,
            iconSize: [15, 15],
            iconAnchor: [7, 7]
        });
    };

    useEffect(() => {
        if (!routeData || !routeData.stoppers || !routeData.stoppers.length) return;
    
        const container = L.DomUtil.get('map');
        if (container != null) {
            container._leaflet_id = null;
        }
    
        const map = L.map('map').setView(mapState.center, mapState.zoom);
        
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 0,
            maxZoom: 20,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    
        const waypoints = routeData.stoppers.map(stopper => 
            L.latLng(stopper.latitude, stopper.longitude)
        );
    
        L.Routing.control({
            waypoints: waypoints,
            routeWhileDragging: true,
            lineOptions: {
                styles: [{ color: '#0073FF', weight: 4 }]
            },
            createMarker: function(i, wp) {
                const color = i === 0 ? colors.blue : 
                            i === waypoints.length - 1 ? colors.green : 
                            colors.blue;
                return L.marker(wp.latLng, {
                    icon: createCustomIcon(color)
                });
            }
        }).addTo(map);
    
        // Add mocked vehicle marker near the last waypoint
        const lastWaypoint = waypoints[waypoints.length - 1];
        L.marker(
            [
                lastWaypoint.lat + (Math.random() - 0.5),
                lastWaypoint.lng + (Math.random() - 0.5)
            ],
            {
                icon: createCustomIcon(colors.teal)
            }
        ).bindPopup(`
            <div>
                <div>Nr rejestracyjny: WWA 11234</div>
                <div>Data ost. pozycji: ${
                    new Date().toLocaleString('pl-PL', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    }).replace(',', '')
                }</div>
            </div>
        `).addTo(map);
    
        return () => {
            map.remove();
        };
    }, [routeData]);

    return (
        <div className="map-modal__map">
            {!routeData ? (
                <Map 
                    {...mapState}
                    style={{ height: '100%' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup>
                        {mapData && mapData.map((mD) => {
                            const stopper = stopperByType(mD);
                            if (!stopper) return null;
                            
                            return (
                                <React.Fragment key={mD.id}>
                                    <Marker
                                        key={mD.id}
                                        position={[stopper.latitude, stopper.longitude]}
                                        icon={createCustomIcon(colorByType(mD))}
                                        onClick={() => {
                                            setShipping(mD);
                                            mD.pointType !== 2 && setRouteData(mD);
                                        }}
                                    >
                                        <Popup>
                                            <div>
                                                №{mD.shippingNumber} 
                                                {mD.pointType !== 2 
                                                    ? ` - ${mD.cost} ₽` 
                                                    : (isTransportCompanyUser 
                                                        ? '' 
                                                        : ` - ${mD.codeFmTransortCompany} | ${mD.codeFmVehicleType}`)
                                                }
                                            </div>
                                        </Popup>
                                    </Marker>
                                    {/* Add mocked vehicle marker near the first coordinate */}
                                    <Marker
                                        key={mD.id}
                                        position={[
                                            stopper.latitude + (Math.random() - 0.5),
                                            stopper.longitude + (Math.random() - 0.5)
                                          ]}
                                        icon={createCustomIcon(colors.teal)}
                                    >
                                        <Popup>
                                            <div>
                                                <div>Nr rejestracyjny: WWA 11234</div>
                                                <div>Data ost. pozycji: {
                                                    new Date().toLocaleString('pl-PL', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }).replace(',', '')
                                                }</div>
                                            </div>
                                        </Popup>
                                    </Marker>
                                </React.Fragment>
                            );
                        })}
                    </MarkerClusterGroup>
                </Map>
            ) : (
                <div id="map" style={{ height: '100%' }}></div>
            )}
        </div>
    );
};

export default LeafletMap;