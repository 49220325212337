import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {REGISTRIES_FOR_PAYMENT_TYPE} from '../../constants/reportType';
import {useTranslation} from 'react-i18next';
import List from "../../containers/customGrid/list";
import {SHIPPINGS_GRID} from "../../constants/grids";
import {Button, Confirm, Popup} from "semantic-ui-react";
import {canDeleteSelector, deleteProgressSelector, deleteRows, progressSelector} from "../../ducks/gridList";
import RegistriesForPaymentUpdateModal from "./registriesForPaymentUpdateModal";
import qs from "qs";
import {lockRepresentation} from "../../ducks/representations";
import {checkShippingNumber} from "../../ducks/reports";
import {toast} from "react-toastify";

const RegistriesForPaymentReport = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const loading = useSelector(state => progressSelector(state));
    const deleteProgress = useSelector(state => deleteProgressSelector(state));

    let [click, setClick] = useState(false);
    let [trigger, setTrigger] = useState(false);
    let [params, setParams] = useState({});
    let [open, setOpen] = useState(false);
    let [editMode, setEditMode] = useState(false);
    let [rowForDelete, setRowForDelete] = useState(null);

    const generateReport = (ps) => {
        setParams(ps);
        setClick(true);
        setTrigger(tr => !tr);
    };

    const location = useLocation();
    const {mode} = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

    useEffect(()=>{
        const editModes = [
            '0', // Перевозки документы сданы
            '1', // Перевозки документы не сданы
            '5' // ЖД перевозки
        ];
        const lockRepresentationModes = [
            '2', // Подтверждённые
            '3' // Архивные
        ];
        setEditMode(editModes.includes(mode));
        dispatch(lockRepresentation({name: REGISTRIES_FOR_PAYMENT_TYPE, value: lockRepresentationModes.includes(mode)}));
    }, [location]);

    const cardLinkFunc = (number, goToCard) => {
        dispatch(checkShippingNumber({
            number,
            callback: (a) => {
                a ? goToCard() : toast.error(t('shippingNumberNotFound'));
            }
        }));
    }

    const canDelete = useSelector(state => canDeleteSelector(state, REGISTRIES_FOR_PAYMENT_TYPE));

    const onDelete = () => dispatch(deleteRows({
        name: REGISTRIES_FOR_PAYMENT_TYPE,
        data: {
            id: rowForDelete.id
        },
        callbackSuccess: () => {
            setRowForDelete(null);
            rowForDelete.callback && rowForDelete.callback();
            setTrigger(tr=>!tr);
        }
    }));

    return (
        <div>
            <List
                useBigLoader={true}
                filters={params}
                isReport
                isRefresh
                reportWithCheckbox
                nameReport={REGISTRIES_FOR_PAYMENT_TYPE}
                trigger={trigger}
                clickReport={click}
                cardLinkFunc={cardLinkFunc}
                cardLink={`/grid/${SHIPPINGS_GRID}/:id`}
                editableColumns={editMode ? ['statusByTC', 'statusByFM', 'transportCompanyComments', 'fmComments'] : []}
                generateBtn={<Popup
                    content={t('Update')}
                    position="bottom right"
                    trigger={
                        <Button icon='refresh' onClick={()=>setOpen(true)} disabled={loading}/>
                    }
                />}
                customBtns={canDelete && mode === '2' && [
                    {
                        name: 'deleteRegistry',
                        onClick: (ids, callbackFunction) => {
                            setRowForDelete({id: ids[0], callback: callbackFunction});
                        },
                        icon: 'trash alternate',
                        disabled: (ids) => ids.length !== 1,
                        loading: deleteProgress
                    }
                ]}
            />
            <Confirm
                open={!!rowForDelete}
                content={t('deleteRegistryConfirmText')}
                onCancel={()=>setRowForDelete(null)}
                cancelButton={t('cancelConfirm')}
                onConfirm={onDelete}
            />
            <RegistriesForPaymentUpdateModal onSave={generateReport} open={open} setModalOpen={setOpen}/>
        </div>
    );
};

export default RegistriesForPaymentReport;