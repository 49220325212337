import { createSelector } from "reselect";
import { downloader, postman } from "../utils/postman";
import {
    all,
    cancel,
    cancelled,
    delay,
    fork,
    put,
    select,
    takeEvery,
} from "redux-saga/effects";
import { IS_AUTO_UPDATE } from "../constants/settings";
import { toast } from "react-toastify";
import { representationFromGridSelector } from "./representations";
import downloadFile from "../utils/downloadFile";
import { FREE_TRANSPORTS_GRID, ORDERS_GRID } from "../constants/grids";
import { REGISTRIES_FOR_PAYMENT_TYPE } from "../constants/reportType";

let task = null;
//let filters = {};

//*  TYPES  *//

const GET_GRID_LIST_REQUEST = "GET_GRID_LIST_REQUEST";
const GET_GRID_LIST_SUCCESS = "GET_GRID_LIST_SUCCESS";
const GET_GRID_LIST_ERROR = "GET_GRID_LIST_ERROR";

const GET_STATE_COLORS_REQUEST = "GET_STATE_COLORS_REQUEST";
const GET_STATE_COLORS_SUCCESS = "GET_STATE_COLORS_SUCCESS";
const GET_STATE_COLORS_ERROR = "GET_STATE_COLORS_ERROR";

const GRID_IMPORT_FROM_EXCEL_REQUEST = "GRID_IMPORT_FROM_EXCEL_REQUEST";
const GRID_IMPORT_FROM_EXCEL_SUCCESS = "GRID_IMPORT_FROM_EXCEL_SUCCESS";
const GRID_IMPORT_FROM_EXCEL_ERROR = "GRID_IMPORT_FROM_EXCEL_ERROR";

const GRID_EXPORT_TO_EXCEL_REQUEST = "GRID_EXPORT_TO_EXCEL_REQUEST";
const GRID_EXPORT_TO_EXCEL_SUCCESS = "GRID_EXPORT_TO_EXCEL_SUCCESS";
const GRID_EXPORT_TO_EXCEL_ERROR = "GRID_EXPORT_TO_EXCEL_ERROR";

const UPLOAD_REGISTRIES_REQUEST = "UPLOAD_REGISTRIES_REQUEST";
const UPLOAD_REGISTRIES_SUCCESS = "UPLOAD_REGISTRIES_SUCCESS";
const UPLOAD_REGISTRIES_ERROR = "UPLOAD_REGISTRIES_ERROR";

const DELETE_ROWS_REQUEST = "DELETE_ROWS_REQUEST";
const DELETE_ROWS_SUCCESS = "DELETE_ROWS_SUCCESS";
const DELETE_ROWS_ERROR = "DELETE_ROWS_ERROR";

const GRID_MODE_COUNTERS_SUCCESS = "GRID_MODE_COUNTERS_SUCCESS";
const GRID_MODE_COUNTERS_ERROR = "GRID_MODE_COUNTERS_ERROR";

const GRID_AUTO_UPDATE_START = "GRID_AUTO_UPDATE_START";
const GRID_AUTO_UPDATE_STOP = "GRID_AUTO_UPDATE_STOP";

const SAVE_GRID_FILTERS = "SAVE_GRID_FILTERS";
const CLEAR_GRID_FILTERS = "CLEAR_GRID_FILTERS";

const CLEAR_GRID_INFO = "CLEAR_GRID_INFO";

//*  INITIAL STATE  *//

const initial = {
    data: [],
    totalCount: 0,
    stateColors: [],
    counters: [],
    progress: false,
    stateColorsProgress: false,
    importProgress: false,
    exportProgress: false,
    deleteProgress: false,
    filters: {},
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_GRID_LIST_REQUEST:
            return {
                ...state,
                progress: !payload.notLoader,
            };
        case UPLOAD_REGISTRIES_REQUEST:
            return {
                ...state,
                uploadRegistriesProgress: true,
            };
        case UPLOAD_REGISTRIES_SUCCESS:
        case UPLOAD_REGISTRIES_ERROR:
            return {
                ...state,
                uploadRegistriesProgress: false,
            };
        case GET_GRID_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                totalCount: payload.totalCount,
                data: payload.isConcat
                    ? [...state.data, ...payload.items]
                    : payload.items,
            };
        case GET_GRID_LIST_ERROR:
            return {
                ...state,
                data: [],
                totalCount: 0,
                progress: false,
            };
        case GET_STATE_COLORS_REQUEST:
            return {
                ...state,
                stateColorsProgress: true,
            };
        case GET_STATE_COLORS_SUCCESS:
            return {
                ...state,
                stateColors: payload,
                stateColorsProgress: false,
            };
        case GET_STATE_COLORS_ERROR:
            return {
                ...state,
                stateColors: [],
                stateColorsProgress: false,
            };
        case GRID_IMPORT_FROM_EXCEL_REQUEST:
            return {
                ...state,
                importProgress: true,
            };
        case GRID_IMPORT_FROM_EXCEL_SUCCESS:
        case GRID_IMPORT_FROM_EXCEL_ERROR:
            return {
                ...state,
                importProgress: false,
            };
        case GRID_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true,
            };
        case GRID_EXPORT_TO_EXCEL_SUCCESS:
        case GRID_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false,
            };
        case GRID_MODE_COUNTERS_SUCCESS:
            return {
                ...state,
                counters: payload,
            };
        case GRID_MODE_COUNTERS_ERROR:
            return {
                ...state,
                counters: [],
            };
        case SAVE_GRID_FILTERS:
            return {
                ...state,
                progress: true,
                filters: payload,
            };
        case CLEAR_GRID_FILTERS:
            return {
                ...state,
                filters: {},
            };
        case CLEAR_GRID_INFO:
            return {
                ...state,
                ...initial,
            };
        case DELETE_ROWS_REQUEST:
            return {
                ...state,
                deleteProgress: true,
            };
        case DELETE_ROWS_SUCCESS:
        case DELETE_ROWS_ERROR:
            return {
                ...state,
                deleteProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getListRequest = (payload) => {
    return {
        type: GET_GRID_LIST_REQUEST,
        payload,
    };
};

export const unloadRegistries = (payload) => {
    return {
        type: UPLOAD_REGISTRIES_REQUEST,
        payload,
    };
};

export const getStateColorsRequest = (payload) => {
    return {
        type: GET_STATE_COLORS_REQUEST,
        payload,
    };
};

export const autoUpdateStart = (payload) => {
    return { type: GRID_AUTO_UPDATE_START, payload };
};

export const autoUpdateStop = (payload) => {
    return { type: GRID_AUTO_UPDATE_STOP, payload };
};

export const importFromExcelRequest = (payload) => {
    return {
        type: GRID_IMPORT_FROM_EXCEL_REQUEST,
        payload,
    };
};

export const exportToExcelRequest = (payload) => {
    return {
        type: GRID_EXPORT_TO_EXCEL_REQUEST,
        payload,
    };
};

export const clearGridInfo = () => {
    return {
        type: CLEAR_GRID_INFO,
    };
};

export const deleteRows = (payload) => {
    return {
        type: DELETE_ROWS_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = (state) => state.gridList;
const getKey = (state, key = "progress") => key;
const stateProfile = (state) => state.profile;
const gridName = (state, name) => name;

const allGrids = (state) => {
    const reportGrids = state.reportGrids || [];
    const grids = state.grids || [];
    const pcGrids = (state.personalCabinet || {}).personalCabinetGrids || [];
    return [...grids, ...reportGrids, ...pcGrids];
};

export const columnsGridSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.columns : [];
    }
);

export const columnsSettingsSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid =
            state.settings && state.settings.find((item) => item.name === name);
        return grid ? grid.columns : [];
    }
);

export const isReadOnlyFieldsSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const reportGrids = state.reportGrids || [];
        const grids = state.grids || [];
        const pcGrids =
            (state.personalCabinet || {}).personalCabinetGrids || [];
        const allGrids = [...grids, ...reportGrids, ...pcGrids];
        const grid = allGrids.find((item) => item.name === name);
        let obj = {};
        (grid
            ? [
                  ...grid.columns,
                  ...(grid.additionalFormColumns || []),
                  ...(grid.additionalFormSectionColumns || []),
              ].filter(
                  (column) =>
                      column.isReadOnlyFieldFor &&
                      column.isReadOnlyFieldFor.length
              )
            : []
        ).forEach((item) => {
            item.isReadOnlyFieldFor.forEach((i) => {
                obj = {
                    ...obj,
                    [i]: item.name,
                };
            });
        });

        return obj;
    }
);

export const columnsFormSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);

        let columns = [];

        if (grid.columns) {
            columns = [...columns, ...grid.columns];
        }

        columns = [
            ...columns,
            ...(grid.additionalFormColumns || []),
            ...(grid.additionalFormSectionColumns || []),
        ];

        return columns;
    }
);

export const modesFromGridSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.modes : [];
    }
);

export const gridModesCountersSelector = createSelector(
    stateSelector,
    (state) => {
        let counters = {};
        state.counters.forEach((item) => {
            counters = {
                ...counters,
                [item.mode]: item.counter,
            };
        });

        return counters;
    }
);

export const progressSelector = createSelector(
    stateSelector,
    (state) => state.progress
);
export const totalCountSelector = createSelector(
    stateSelector,
    (state) => state.totalCount
);
export const listSelector = createSelector(
    stateSelector,
    (state) => state.data
);

export const stateColorsSelector = createSelector(
    stateSelector,
    (state) => state.stateColors
);

export const uploadRegistriesProgressSelector = createSelector(
    stateSelector,
    (state) => state.uploadRegistriesProgress
);

export const canCreateByFormSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.canCreateByForm : false;
    }
);

export const canImportFromExcelSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.canImportFromExcel : false;
    }
);

export const canExportToExcelSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.canExportToExcel : false;
    }
);

export const canDeleteSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid ? grid.canDelete : false;
    }
);

const actionName = (state, name, actionName) => actionName;

export const additionlActionsSelector = createSelector(
    [stateProfile, gridName, actionName],
    (state, name, actionName) => {
        const grid = allGrids(state).find((item) => item.name === name);
        return grid
            ? (grid.additionlActions || []).find((a) => a === actionName)
            : false;
    }
);

export const autogroupingOrdersSelector = createSelector(
    [stateProfile, gridName],
    (state, name) => state.autogroupingOrders && name === ORDERS_GRID
);

export const importProgressSelector = createSelector(
    stateSelector,
    (state) => state.importProgress
);

export const exportProgressSelector = createSelector(
    stateSelector,
    (state) => state.exportProgress
);

export const deleteProgressSelector = createSelector(
    stateSelector,
    (state) => state.deleteProgress
);

export const filtersSelector = createSelector(
    stateSelector,
    (state) => state.filters
);

//*  SAGA  *//

export function* getListSaga({ payload }) {
    try {
        const {
            filter = {},
            name,
            isConcat,
            scrollTop,
            isReport = false,
            reportFilters = {},
            isRefresh,
            noModes,
            callbackSuccess,
        } = payload;

        const representation = yield select((state) =>
            representationFromGridSelector(state, name)
        );
        const columns = representation
            ? representation.map((item) => item.name)
            : [];

        console.log(columns);

        const result = yield postman.post(
            `/${name}/${
                isReport ? (isRefresh ? "refresh" : "generatereport") : "search"
            }`,
            {
                ...filter,
                filter: {
                    ...filter.filter,
                    columns,
                },
                ...reportFilters,
            }
        );

        const data = { name };

        if (isRefresh) {
            data["startDate"] = reportFilters.startDate;
            data["endDate"] = reportFilters.endDate;
        }

        yield !noModes && fork(getGridMode, data);

        yield !result.isError &&
            put({
                type: GET_GRID_LIST_SUCCESS,
                payload: { ...result, isConcat },
            });

        if (result.isError) {
            toast.error(result.message);
            yield put({ type: GET_GRID_LIST_ERROR });
        }

        scrollTop && scrollTop();

        callbackSuccess?.(result?.items);
    } catch (error) {
        yield put({ type: GET_GRID_LIST_ERROR, payload: error });
    }
}

export function* unloadRegistriesSaga({ payload }) {
    try {
        const { filter = {}, name, reportFilters = {} } = payload;

        const representation = yield select((state) =>
            representationFromGridSelector(state, name)
        );
        const columns = representation
            ? representation.map((item) => item.name)
            : [];

        const result = yield downloader.post(
            `/${name}/unloadRegistries`,
            {
                ...filter,
                filter: {
                    ...filter.filter,
                    columns,
                },
                ...reportFilters,
                skip: 0,
                take: 0,
            },
            {
                responseType: "blob",
            }
        );

        downloadFile(result);
        yield put({ type: UPLOAD_REGISTRIES_SUCCESS });
    } catch (error) {
        yield put({ type: UPLOAD_REGISTRIES_ERROR });
    }
}

function* getGridMode({ name, startDate, endDate }) {
    try {
        const modes = yield select((state) =>
            modesFromGridSelector(state, name)
        ) || [];
        const modesArray = (
            modes.filter((item) => item.isCounterVisible) || []
        ).map((item) => item.mode);
        const result = yield postman.get(`${name}/gridModeCounters`, {
            params: { modes: modesArray, startDate, endDate },
        });

        yield put({
            type: GRID_MODE_COUNTERS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GRID_MODE_COUNTERS_ERROR,
        });
    }
}

export function* autoUpdateStartSaga({ payload }) {
    if (IS_AUTO_UPDATE) {
        if (!task) {
            yield put({
                type: SAVE_GRID_FILTERS,
                payload: {
                    ...payload,
                    isConcat: false,
                    notLoader: false,
                    filter: {
                        ...payload.filter,
                        take: payload.filter.take + payload.filter.skip,
                        skip: 0,
                    },
                },
            });
            task = yield fork(backgroundSyncListSaga);
        }
    } else {
        yield put(getListRequest(payload));
    }
}

export function* autoUpdateStopSaga({ payload = {} }) {
    if (task) {
        const { isClear } = payload;
        if (isClear) yield put(clearGridInfo());
        yield task && cancel(task);
        task = null;
        yield put({
            type: CLEAR_GRID_FILTERS,
        });
    }
}

export const backgroundSyncListSaga = function* () {
    try {
        while (true) {
            const filters = yield select(filtersSelector);
            yield put(getListRequest(filters));
            yield put({
                type: SAVE_GRID_FILTERS,
                payload: {
                    ...filters,
                    notLoader: true,
                },
            });
            yield delay(30000);
        }
    } finally {
        if (yield cancelled()) {
            console.log("---", "cancelled sync saga");
        }
    }
};

function* getStateColorsSaga({ payload }) {
    try {
        const result = yield postman.post(`${payload}/search`);

        yield put({
            type: GET_STATE_COLORS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_STATE_COLORS_ERROR,
            payload: e,
        });
    }
}

function* importFromExcelSaga({ payload }) {
    try {
        const { form, name, callbackSuccess } = payload;
        const result = yield postman.post(`${name}/importFromExcel`, form, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (result.isError) {
            toast.error(result.message);
        } else {
            yield put({
                type: GRID_IMPORT_FROM_EXCEL_SUCCESS,
            });

            callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: GRID_IMPORT_FROM_EXCEL_ERROR,
        });
    }
}

function* deleteRowsSaga({ payload }) {
    try {
        const { data, name, callbackSuccess } = payload;
        const methodIsDelete = [
            FREE_TRANSPORTS_GRID,
            REGISTRIES_FOR_PAYMENT_TYPE,
        ].includes(name);
        const result = yield methodIsDelete
            ? postman.delete(`${name}/delete`, { params: { ...data } })
            : postman.post(`${name}/delete`, data);

        if (result.isError) {
            toast.error(result.message);
            yield put({
                type: DELETE_ROWS_ERROR,
            });
        } else {
            yield put({
                type: DELETE_ROWS_SUCCESS,
            });
        }

        callbackSuccess && callbackSuccess();
    } catch (e) {
        toast.error("Error");
        yield put({
            type: DELETE_ROWS_ERROR,
        });
    }
}

function* exportToExcelSaga({ payload }) {
    try {
        const { name, filter, reportFilters } = payload;
        const representation = yield select((state) =>
            representationFromGridSelector(state, name)
        );
        const columns = representation
            ? representation.map((item) => item.name)
            : [];

        const res = yield downloader.post(
            `/${name}/exportToExcel`,
            { columns, ...filter, ...reportFilters },
            { responseType: "blob" }
        );

        downloadFile(res);
        yield put({ type: GRID_EXPORT_TO_EXCEL_SUCCESS });
    } catch (error) {
        yield put({ type: GRID_EXPORT_TO_EXCEL_ERROR });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_GRID_LIST_REQUEST, getListSaga),
        takeEvery(UPLOAD_REGISTRIES_REQUEST, unloadRegistriesSaga),
        takeEvery(GRID_AUTO_UPDATE_START, autoUpdateStartSaga),
        takeEvery(GRID_AUTO_UPDATE_STOP, autoUpdateStopSaga),
        takeEvery(GET_STATE_COLORS_REQUEST, getStateColorsSaga),
        takeEvery(GRID_IMPORT_FROM_EXCEL_REQUEST, importFromExcelSaga),
        takeEvery(GRID_EXPORT_TO_EXCEL_REQUEST, exportToExcelSaga),
        takeEvery(DELETE_ROWS_REQUEST, deleteRowsSaga),
    ]);
}
