import React from 'react';
import LinksBlock from "./linksBlock";
import {PERSONAL_CABINET_ADDITIONAL_INFORMATION} from "../../constants/personalCabinetPages";

const AdditionalInformation = () => {
    return (
        <LinksBlock
            type={1}
            name={PERSONAL_CABINET_ADDITIONAL_INFORMATION}
            color={'yellow'}
            icon={'info circle'}
        />
    );
};

export default AdditionalInformation;