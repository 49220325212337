import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Card, Divider, Button, Accordion, List, Form
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { SHIPPINGS_GRID } from '../../../../constants/grids';
import { historySelector } from '../../../../ducks/history';
import { getSecurityCasesRequest } from '../../../../ducks/securityCases';
import {
    checkDriver, assignAuctionWinnerRequest, isExistModel, checkRequiredFields, setCheckRequiredFields, setIsExistModel,
} from '../../../../ducks/gridCard';
import { dateToUTC } from '../../../../utils/dateTimeFormater';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK } from '../../../../router/links';
import { APPROVED } from '../../../../constants/securityServiceStates';
import { ATTRACTED, LOAN } from '../../../../constants/ownershipTypes';
import FormField from '../formField';

const transportCompanyVehicleOwnershipType = 'transportCompanyVehicleOwnershipType';
const transportCompanyTrailerOwnershipType = 'transportCompanyTrailerOwnershipType';
const trailerId = 'trailerId';
const vehicleId = 'vehicleId';

const phoneNumberBindingType = {
    bindByDriver: 0,
    bindByVehicle: 1
};

const StyledCardHeader = styled(Card.Content)`
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;

    font-size: 16px;
    font-weight: 500;
`;

const StyledParagraph = styled.p`
    font-size: 14px;
    font-weight: 600;
`;

const CardTextItem = ({ label, value }) => (
    <StyledParagraph>
        <span style={{ color: '#8A8A8A' }}>{`${label}: `}</span>
        <span style={{ fontWeight: '400' }}>{`${value || '-'}`}</span>
    </StyledParagraph>
);

const StyledAccordion = styled(Accordion)`
    & > .title {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        margin-bottom: 8px;
        padding: 10px !important;
        border: 1px solid #135CA9;
        border-radius: 4px;
    }
`;

const ShippingCard = ({
    form,
    onChangeForm,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
    updateHistory,
    saveForm,
    scrollTo = null,
    actions,
    renderMobileActions,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const historyApi = useHistory();
    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};
    const history = useSelector((state) => historySelector(state));

    const dispatch = useDispatch();

    const getSecurityCases = (id) => {
        id && dispatch(getSecurityCasesRequest(id));
    };

    useEffect(() => {
        getSecurityCases(id);
    }, [id]);

    useEffect(()=>{
        return () => {
            dispatch(setCheckRequiredFields(true));
            dispatch(setIsExistModel({vehicles: true}));
            dispatch(setIsExistModel({trailers: true}));
        }
    }, []);

    useEffect(() => {
        //для проверки ИНН и ВУ при добавлении водителя через соответствующую кнопку
        if (location.state && location.state.lookup && location.state.name === "editableDriverId") {
            dispatch(checkDriver(location.state.lookup.value));
            window.history.replaceState(null, '');
        }
        //для проверки полей при добавлении ТС через соответствующую кнопку
        if (location.state && location.state.lookup && location.state.name === vehicleId) {
            dispatch(checkRequiredFields(location.state.lookup.value));
            window.history.replaceState(null, '');
        }
    }, [location.state]);

    const panels = form.stoppers?.map((stopper, i) => ({
        key: `panel-${i}`,
        title: {
            content: `${stopper.stopperType} ${stopper.index}`,
        },
        content: {
            content: (
                <>
                    <CardTextItem
                        label={t(stopper.isDelivery ? 'stopper_clientNameDelivery' : 'stopper_clientNameLoading')}
                        value={stopper.clientName}
                    />
                    <CardTextItem label={t('estimatedDateTime')} value={stopper.dateTime} />
                    <CardTextItem label={t('tariffZoneMoscow')} value={stopper.tariffZoneMoscow} />
                    <CardTextItem label={t('driverArrivalTimeComment')} value={stopper.driverArrivalTimeComment} />
                    <CardTextItem label={t('factArrivalTime')} value={stopper.factArrivalTime} />
                    <CardTextItem label={t('factDepartureTime')} value={stopper.factDepartureTime} />
                    <CardTextItem label={t('stopper_address1')} value={stopper.address1} />
                    <CardTextItem label={t('stopper_address2And3')} value={stopper.address2And3} />
                    <CardTextItem label={t('city')} value={stopper.city} />
                    <CardTextItem label={t('stopper_postalCode')} value={stopper.postalCode} />
                    <CardTextItem label={t('stopper_cargoUnits')} value={stopper.cargoUnits} />
                    <CardTextItem label={t('pointWeight')} value={stopper.pointWeight} />
                    <CardTextItem label={t('stopper_instruction')} value={stopper.instruction} />
                </>
            ),
        },
    }));

    const setDriver = (e, { value, name }) => {
        onChangeForm(e, {
            name,
            value,
        });

        if (
            (form[vehicleId] && !form[vehicleId].driverTelephoneNumber) // если в текущем выбранном ТС нет номера телефона водителя, то номер берем из данных водителя
            || (value && (value.phoneNumberBindingType === phoneNumberBindingType.bindByDriver))
        ) {
            onChangeForm(e, {
                name: 'driverTelephoneNumber',
                value: value ? value.driverTelephoneNumber : '',
            });
        }
        if (value) {
            dispatch(checkDriver(value.value));
        }
    };

    const innAtiCodeDisabled = () => (
        (form[vehicleId] && form[vehicleId].securityServiceCheckState === APPROVED)
        && (
            !form[trailerId]
            || (form[trailerId] && form[trailerId].securityServiceCheckState === APPROVED)
        )
        && (form.editableDriverId && form.editableDriverId.securityServiceCheckState === APPROVED)
    );

    const handleChangeOwnershipType = useCallback((e, { name, value }) => {
        onChangeForm(e, {
            name,
            value,
        });

        if (value && name === vehicleId) {
            dispatch(checkRequiredFields(value.value));
            dispatch(isExistModel({ name: 'vehicles', form: { [vehicleId]: value.value } }));
        }

        if (value && name === trailerId) {
            dispatch(isExistModel({ name: 'trailers', form: { [trailerId]: value.value } }));
        }

        if (name === transportCompanyVehicleOwnershipType && [ATTRACTED, LOAN].includes(value)) {
            onChangeForm(e, {
                name: transportCompanyTrailerOwnershipType,
                value,
            });
        }
        if (name === transportCompanyVehicleOwnershipType
            && ![ATTRACTED, LOAN].includes(value)
            && [ATTRACTED, LOAN].includes(form[transportCompanyVehicleOwnershipType])) {
            onChangeForm(e, {
                name: transportCompanyTrailerOwnershipType,
                value: form[trailerId] ? form[trailerId].transportCompanyOwnershipType : null,
            });
        }
        if (name === vehicleId && !value) {
            onChangeForm(e, {
                name: transportCompanyVehicleOwnershipType,
                value: null,
            });
            onChangeForm(e, {
                name: 'carrierVehicleOwnershipType',
                value: null,
            });
        }
        if (name === vehicleId && value) { // автозаполнение из ТС
            onChangeForm(e, {
                name: transportCompanyVehicleOwnershipType,
                value: value.transportCompanyOwnershipType,
            });
            onChangeForm(e, {
                name: 'carrierName',
                value: value.carrier,
            });
            onChangeForm(e, {
                name: 'inn',
                value: value.carrierInn,
            });
            onChangeForm(e, {
                name: 'legalAddress',
                value: value.carrierLegalAddress,
            });
            onChangeForm(e, {
                name: 'telephoneNumber',
                value: value.carrierTelephoneNumber,
            });
            onChangeForm(e, {
                name: 'carrierVehicleOwnershipType',
                value: value.carrierOwnershipType,
            });
            if (
                value.driverTelephoneNumber
                && (value.phoneNumberBindingType === phoneNumberBindingType.bindByVehicle
                )) { // номер телефона водителя из ТС
                onChangeForm(e, {
                    name: 'driverTelephoneNumber',
                    value: value.driverTelephoneNumber,
                });
            } else if (form.editableDriverId && form.editableDriverId.driverTelephoneNumber) {
                onChangeForm(e, {
                    name: 'driverTelephoneNumber',
                    value: form.editableDriverId.driverTelephoneNumber,
                });
            }
        }
        if (name === trailerId && value) {
            onChangeForm(e, {
                name: 'carrierTrailerOwnershipType',
                value: value.carrierOwnershipType,
            });
            onChangeForm(e, {
                name: transportCompanyTrailerOwnershipType,
                value: ![ATTRACTED, LOAN].includes(form[transportCompanyVehicleOwnershipType])
                    ? value.transportCompanyOwnershipType : form[transportCompanyVehicleOwnershipType],
            });
        }
        if (name === trailerId && !value) {
            onChangeForm(e, {
                name: transportCompanyTrailerOwnershipType,
                value: null,
            });
            onChangeForm(e, {
                name: 'carrierTrailerOwnershipType',
                value: null,
            });
        }
    }, [form]);

    const handleSave = (priceOfferId) => {
        dispatch(
            assignAuctionWinnerRequest({
                priceOfferId,
                cardId: id,
                shippingVersion: form.rowVersion,
                callbackSuccess: load,
            }),
        );
    };

    const StyledWrap = styled.div`
        margin-top: 10px;

        p {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
    `;

    const StyledListItem = styled(List.Item)`
        border-radius: 8px;
        background: rgb(46, 105, 164) !important;
        background: linear-gradient(0deg, rgba(46, 105, 164, 1) 0%, rgba(28, 120, 211, 1) 100%) !important;
        color: white;
        padding: 16px 20px !important;
        margin: 10px 0;

        & > .content {
            margin-bottom: 4px;

            & > .button {
                margin: 12px 0 0 0;
                color: white;
                background-color: #FFFFFF14;
                border: 1px solid white;
                font-size: 16px;
            }
        }
    `;

    const StyledFieldWrapper = styled.div`
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        gap: 4px;

        & > .icon.button {
            margin: 0;
        }

        .field, .form-select, .dropdown, .input {
            width: -webkit-fill-available;
        }
    `;

    return (
        <Form style={{ margin: '14px', paddingBottom: '68px' }}>
            {/* <StyledButton fluid content="Действия с перевозкой" /> */}
            {renderMobileActions()}

            {form.auctionPriceOffers?.items?.length && form.auctionPriceOffers.isVisible ? (
                <StyledWrap>
                    <p>{t('selectBet')}</p>

                    <List>
                        {form.auctionPriceOffers.items.map((offer) => (
                            <StyledListItem key={offer.id}>
                                <List.Content>
                                    {t('transportCompanyId')}
                                    :
                                    {' '}
                                    {offer?.transportCompanyId?.name}
                                </List.Content>
                                <List.Content>
                                    {t('responsiblePhoneNumber')}
                                    :
                                    <a href={`tel:${offer?.responsiblePhoneNumber}`} style={{ color: 'white', textDecoration: 'underline' }}>{offer?.responsiblePhoneNumber}</a>
                                </List.Content>
                                <List.Content>
                                    {t('securityServiceDenyPercent')}
                                    :
                                    {' '}
                                    {offer?.securityServiceDenyPercent}
                                </List.Content>
                                <List.Content>
                                    {t('PriceOffers.OfferAmount')}
                                    :
                                    {' '}
                                    {offer?.offerAmount}
                                </List.Content>
                                <List.Content>
                                    {t('creationDate')}
                                    :
                                    {' '}
                                    {dateToUTC(offer?.creationDate, 'DD.MM.YYYY HH:mm', true)}
                                </List.Content>

                                {form.auctionPriceOffers.isSelectButtonsVisible && (
                                    <List.Content>
                                        <Button fluid onClick={() => handleSave(offer.id)}>
                                            {t('confirm')}
                                        </Button>
                                    </List.Content>
                                )}
                            </StyledListItem>
                        ))}
                    </List>
                </StyledWrap>
            ) : null}

            {form.isAuctionVisible ? (
                <Card fluid>
                    <StyledCardHeader>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.24998 7.00152C5.55963 7.00152 4.99998 7.56117 4.99998 8.25152V10.7515C4.99998 11.4419 5.55963 12.0015 6.24998 12.0015H8.74998C9.44034 12.0015 9.99998 11.4419 9.99998 10.7515V8.25152C9.99998 7.56117 9.44034 7.00152 8.74998 7.00152H6.24998ZM6.49998 10.5015V8.50152H8.49998V10.5015H6.49998Z" fill="#1C78D3" />
                            <path d="M11 8.25006C11 7.83584 11.3358 7.50006 11.75 7.50006H14.5C14.9142 7.50006 15.25 7.83584 15.25 8.25006C15.25 8.66427 14.9142 9.00006 14.5 9.00006H11.75C11.3358 9.00006 11 8.66427 11 8.25006Z" fill="#1C78D3" />
                            <path d="M11.75 10.5001C11.3358 10.5001 11 10.8358 11 11.2501C11 11.6643 11.3358 12.0001 11.75 12.0001H14.5C14.9142 12.0001 15.25 11.6643 15.25 11.2501C15.25 10.8358 14.9142 10.5001 14.5 10.5001H11.75Z" fill="#1C78D3" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.24853 4.00146C3.45361 4.00146 1.99854 5.45654 1.99854 7.25147V12.7487C1.99854 14.5436 3.45361 15.9987 5.24853 15.9987H14.7514C16.5464 15.9987 18.0014 14.5436 18.0014 12.7487V7.25146C18.0014 5.45654 16.5464 4.00146 14.7514 4.00146H5.24853ZM3.49854 7.25147C3.49854 6.28497 4.28204 5.50146 5.24853 5.50146H14.7514C15.7179 5.50146 16.5014 6.28497 16.5014 7.25146V12.7487C16.5014 13.7152 15.7179 14.4987 14.7514 14.4987H5.24853C4.28204 14.4987 3.49854 13.7152 3.49854 12.7487V7.25147Z" fill="#1C78D3" />
                        </svg>

                        {t('auctionParameters')}
                    </StyledCardHeader>

                    <Card.Content description>
                        <CardTextItem label={t('auctionType')} value={form?.auctionType?.name} />
                        <CardTextItem label={t('auctionStartingPrice')} value={form?.auctionStartingPrice} />
                        <CardTextItem label={t('auctionStep')} value={form?.auctionStep} />
                        <CardTextItem label={t('auctionStartDateTime')} value={dateToUTC(form?.auctionStartDateTime, 'DD.MM.YYYY HH:mm', true)} />
                        <CardTextItem label={t('auctionFinishDateTime')} value={dateToUTC(form?.auctionFinishDateTime, 'DD.MM.YYYY HH:mm', true)} />
                        <CardTextItem label={t('auctionDecisionType')} value={form?.auctionDecisionType?.name} />
                        <CardTextItem label={t('auctionProlongation')} value={form?.auctionProlongation} />
                    </Card.Content>
                </Card>
            ) : null}

            <Card fluid>
                <StyledCardHeader>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.24998 7.00152C5.55963 7.00152 4.99998 7.56117 4.99998 8.25152V10.7515C4.99998 11.4419 5.55963 12.0015 6.24998 12.0015H8.74998C9.44034 12.0015 9.99998 11.4419 9.99998 10.7515V8.25152C9.99998 7.56117 9.44034 7.00152 8.74998 7.00152H6.24998ZM6.49998 10.5015V8.50152H8.49998V10.5015H6.49998Z" fill="#1C78D3" />
                        <path d="M11 8.25006C11 7.83584 11.3358 7.50006 11.75 7.50006H14.5C14.9142 7.50006 15.25 7.83584 15.25 8.25006C15.25 8.66427 14.9142 9.00006 14.5 9.00006H11.75C11.3358 9.00006 11 8.66427 11 8.25006Z" fill="#1C78D3" />
                        <path d="M11.75 10.5001C11.3358 10.5001 11 10.8358 11 11.2501C11 11.6643 11.3358 12.0001 11.75 12.0001H14.5C14.9142 12.0001 15.25 11.6643 15.25 11.2501C15.25 10.8358 14.9142 10.5001 14.5 10.5001H11.75Z" fill="#1C78D3" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.24853 4.00146C3.45361 4.00146 1.99854 5.45654 1.99854 7.25147V12.7487C1.99854 14.5436 3.45361 15.9987 5.24853 15.9987H14.7514C16.5464 15.9987 18.0014 14.5436 18.0014 12.7487V7.25146C18.0014 5.45654 16.5464 4.00146 14.7514 4.00146H5.24853ZM3.49854 7.25147C3.49854 6.28497 4.28204 5.50146 5.24853 5.50146H14.7514C15.7179 5.50146 16.5014 6.28497 16.5014 7.25146V12.7487C16.5014 13.7152 15.7179 14.4987 14.7514 14.4987H5.24853C4.28204 14.4987 3.49854 13.7152 3.49854 12.7487V7.25147Z" fill="#1C78D3" />
                    </svg>

                    {t('information')}
                </StyledCardHeader>

                <Card.Content description>
                    <CardTextItem label={t('transportCompanyId')} value={form?.transportCompanyId?.name} />
                    <CardTextItem label={t('transportCompanyServiceTypeId')} value={form?.transportCompanyServiceTypeId?.name} />
                    <CardTextItem label={t('shippingTarifficationType')} value={form?.tarifficationType?.name} />
                    <CardTextItem label={t('shippingNumber')} value={form?.shippingNumber} />

                    <Divider />

                    <StyledParagraph>{t('vehicleInfoGroup')}</StyledParagraph>
                    <CardTextItem label={t('vehicleTypeId')} value={form?.vehicleTypeId?.name} />
                    <CardTextItem
                        label={t('temperature')}
                        value={`
                            от ${form?.vehicleTypeTemperatureMin || '-'} до ${form?.vehicleTypeTemperatureMax || '-'}
                        `}
                    />
                    <CardTextItem label={t('vehicleTypeCapacity')} value={form?.vehicleTypeCapacity} />
                    <CardTextItem label={t('vehicleTypeAdditionalCondition')} value={form?.vehicleTypeAdditionalCondition} />
                    <Divider />

                    <StyledParagraph>{t('vehicleId')}</StyledParagraph>
                    <StyledFieldWrapper>
                        <FormField
                            noLabel
                            name={vehicleId}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={handleChangeOwnershipType}
                        />
                        <Button
                            circular
                            icon="arrow right"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_CARD_LINK.replace(':name', 'vehicles').replace(':id', form[vehicleId].value),
                                });
                            }}
                        />
                        <Button
                            circular
                            style={{ background: '#22BA46', color: '#fff' }}
                            icon="plus"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_NEW_LINK.replace(
                                        ':name',
                                        'vehicles',
                                    ),
                                    state: {
                                        pathname: location.pathname,
                                        form,
                                        // autocompleteItems: autocompleteItemsVehicle,
                                        name: vehicleId,
                                    },
                                });
                            }}
                        />
                    </StyledFieldWrapper>
                    <Divider />

                    <StyledParagraph>{t('trailerId')}</StyledParagraph>
                    <StyledFieldWrapper>
                        <FormField
                            noLabel
                            name={trailerId}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={handleChangeOwnershipType}
                        />
                        <Button
                            circular
                            icon="arrow right"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_CARD_LINK.replace(':name', 'trailers').replace(':id', form[trailerId].value),
                                });
                            }}
                        />
                        <Button
                            circular
                            style={{ background: '#22BA46', color: '#fff' }}
                            icon="plus"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_NEW_LINK.replace(
                                        ':name',
                                        'trailers',
                                    ),
                                    state: {
                                        pathname: location.pathname,
                                        form,
                                        // autocompleteItems: form[transportCompanyVehicleOwnershipType] !== ATTRACTED ? autocompleteItemsTrailer : null,
                                        name: trailerId,
                                    },
                                });
                            }}
                        />
                    </StyledFieldWrapper>
                    <Divider />

                    <StyledParagraph>{t('editableDriverId')}</StyledParagraph>
                    <StyledFieldWrapper>
                        <FormField
                            noLabel
                            name="editableDriverId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={setDriver}
                        />

                        <Button
                            circular
                            icon="arrow right"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_CARD_LINK.replace(':name', 'transportCompanyDrivers').replace(':id', form.editableDriverId.value),
                                });
                            }}
                        />
                        <Button
                            circular
                            style={{ background: '#22BA46', color: '#fff' }}
                            icon="plus"
                            onClick={() => {
                                historyApi.push({
                                    pathname: DICTIONARY_NEW_LINK.replace(
                                        ':name',
                                        'transportCompanyDrivers',
                                    ),
                                    state: {
                                        pathname: location.pathname,
                                        form,
                                        name: 'editableDriverId',
                                    },
                                });
                            }}
                        />
                    </StyledFieldWrapper>

                    <StyledParagraph>{t('driverTelephoneNumber')}</StyledParagraph>
                    <StyledFieldWrapper>
                        <FormField
                            noLabel
                            isDisabled={!form.editableDriverId}
                            name="driverTelephoneNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChangeForm}
                        />
                    </StyledFieldWrapper>

                    <StyledParagraph>{t('commentForTheSecurityService')}</StyledParagraph>
                    <StyledFieldWrapper>
                        <FormField
                            noLabel
                            width="100%"
                            name="innAttractedTransport"
                            displayNameKey="commentForTheSecurityService"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            isDisabled={innAtiCodeDisabled()}
                            onChange={onChangeForm}
                        />
                    </StyledFieldWrapper>
                </Card.Content>
            </Card>

            <Card fluid>
                <StyledCardHeader>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_557_12578)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.08039 3.33557C1.27928 2.56208 1.97661 2.02136 2.77527 2.02136H10.9999C10.9996 2.02136 11.0001 2.02136 10.9999 2.02136L17.214 2.02136C18.0211 2.02136 18.7236 2.57335 18.9144 3.35758L19.9141 7.4658C20.1057 8.25282 19.5096 9.01136 18.6996 9.01136H17.9999V13.8C17.9999 15.5949 16.5448 17.05 14.7499 17.05H5.24986C3.45494 17.05 1.99986 15.5949 1.99986 13.8V9.00002H1.236C0.419331 9.00002 -0.177997 8.22968 0.0253741 7.43874L1.08039 3.33557ZM2.77527 3.52136C2.66117 3.52136 2.56155 3.59861 2.53314 3.70911L1.55841 7.50002H8.72996C8.84248 7.50002 8.94114 7.42484 8.971 7.31636L10.0155 3.52136H2.77527ZM16.4999 9.01136H13.2518C12.4614 9.01136 11.7692 8.48164 11.5626 7.71879L10.994 5.61895L10.4172 7.71442C10.2082 8.47382 9.5176 9.00002 8.72996 9.00002H3.49986V13.8C3.49986 14.7665 4.28336 15.55 5.24986 15.55H10.2499V10C10.2499 9.5858 10.5856 9.25002 10.9999 9.25002C11.4141 9.25002 11.7499 9.5858 11.7499 10V15.55H14.7499C15.7164 15.55 16.4999 14.7665 16.4999 13.8V9.01136ZM13.0105 7.32671L11.98 3.52136H17.214C17.3293 3.52136 17.4297 3.60022 17.4569 3.71225L18.3815 7.51136H13.2518C13.1389 7.51136 13.04 7.43569 13.0105 7.32671Z" fill="#1C78D3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_557_12578">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    {t('commonCargoInfo')}
                </StyledCardHeader>

                <Card.Content description>
                    <CardTextItem label={t('shippingCargoUnits')} value={form?.shippingCargoUnits} />
                    <CardTextItem label={t('productTypes')} value={form?.productTypes} />
                    <CardTextItem label={t('weightKg')} value={form?.weightKg} />
                    <CardTextItem label={t('cargoCost')} value={form?.cargoCost} />
                </Card.Content>
            </Card>

            <Card fluid>
                <StyledCardHeader>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.00012 11H11.1501C12.6689 11 13.9001 9.76878 13.9001 8.25C13.9001 6.73122 12.6689 5.5 11.1501 5.5H8.48346C7.94038 5.5 7.50012 5.94025 7.50012 6.48333V9.5H7.00012C6.58591 9.5 6.25012 9.83579 6.25012 10.25C6.25012 10.6642 6.58591 11 7.00012 11H7.50012V12H7.00012C6.58591 12 6.25012 12.3358 6.25012 12.75C6.25012 13.1642 6.58591 13.5 7.00012 13.5H7.50012V14.25C7.50012 14.6642 7.83591 15 8.25012 15C8.66434 15 9.00012 14.6642 9.00012 14.25V13.5H11.5001C11.9143 13.5 12.2501 13.1642 12.2501 12.75C12.2501 12.3358 11.9143 12 11.5001 12H9.00012V11ZM9.00012 7V9.5H11.1501C11.8405 9.5 12.4001 8.94036 12.4001 8.25C12.4001 7.55964 11.8405 7 11.1501 7H9.00012Z" fill="#1C78D3" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.00146 10.0001C2.00146 5.58257 5.58257 2.00146 10.0001 2.00146C14.4176 2.00146 17.9987 5.58257 17.9987 10.0001C17.9987 14.4176 14.4176 17.9987 10.0001 17.9987C5.58257 17.9987 2.00146 14.4176 2.00146 10.0001ZM10.0001 3.50146C6.411 3.50146 3.50146 6.411 3.50146 10.0001C3.50146 13.5892 6.411 16.4987 10.0001 16.4987C13.5892 16.4987 16.4987 13.5892 16.4987 10.0001C16.4987 6.411 13.5892 3.50146 10.0001 3.50146Z" fill="#1C78D3" />
                    </svg>

                    {t('costs')}
                </StyledCardHeader>

                <Card.Content description>
                    <CardTextItem label={t('registryNumber')} value={form?.registryNumber} />
                    <CardTextItem label={t('basicDeliveryCostWithoutVAT')} value={form?.basicDeliveryCostWithoutVAT} />

                    {form.additionalServices?.length ? <Divider /> : null}
                    {form.additionalServices?.length ? form.additionalServices.map((service, index) => (
                        <>
                            <CardTextItem label={t('additionalService')} value={service?.name} />
                            <CardTextItem label={t('quantity')} value={service?.quantity} />
                            <CardTextItem label={t('cost')} value={service?.cost} />

                            {form.additionalServices[index + 1] ? <Divider /> : null}
                        </>
                    )) : null}
                </Card.Content>
            </Card>

            <Card fluid>
                <StyledCardHeader>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 8.78608C3 5.03823 5.98477 2 9.66667 2C13.3486 2 16.3333 5.03823 16.3333 8.78608C16.3333 12.5046 14.2056 16.8437 10.8858 18.3953C10.1119 18.7571 9.22142 18.7571 8.44758 18.3953C5.12777 16.8437 3 12.5046 3 8.78608Z" stroke="#1C78D3" strokeWidth="1.6" />
                        <path d="M9.66675 11.1667C11.0475 11.1667 12.1667 10.0475 12.1667 8.66675C12.1667 7.28604 11.0475 6.16675 9.66675 6.16675C8.28604 6.16675 7.16675 7.28604 7.16675 8.66675C7.16675 10.0475 8.28604 11.1667 9.66675 11.1667Z" stroke="#1C78D3" strokeWidth="1.6" />
                    </svg>

                    {t('stoppersFormCaption')}
                </StyledCardHeader>

                <Card.Content description>
                    <StyledAccordion fluid defaultActiveIndex={1} panels={panels} />
                </Card.Content>
            </Card>

            <Card fluid>
                <StyledCardHeader>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7973 3.75499C10.4028 3.35363 8.91414 3.43054 7.56844 3.97347C6.31183 4.48045 5.24743 5.36653 4.52122 6.50488H5.99991C6.41412 6.50488 6.74991 6.84067 6.74991 7.25488C6.74991 7.6691 6.41412 8.00488 5.99991 8.00488H2.75024C2.33603 8.00488 2.00024 7.6691 2.00024 7.25488V3.75488C2.00024 3.34067 2.33603 3.00488 2.75024 3.00488C3.16446 3.00488 3.50024 3.34067 3.50024 3.75488V5.33824C4.38111 4.11009 5.59564 3.15192 7.00722 2.58241C8.66354 1.91417 10.4959 1.81951 12.2122 2.31351C13.9286 2.80751 15.4302 3.86174 16.4779 5.30824C17.5255 6.75473 18.0589 8.51025 17.9931 10.2951C17.9272 12.0799 17.2659 13.7913 16.1144 15.1567C14.963 16.522 13.3878 17.4627 11.6396 17.8288C9.89154 18.195 8.07118 17.9655 6.46863 17.177C4.86607 16.3885 3.57352 15.0863 2.79693 13.478C2.61683 13.105 2.77321 12.6566 3.14622 12.4765C3.51923 12.2964 3.96762 12.4527 4.14772 12.8258C4.77867 14.1325 5.82882 15.1905 7.13085 15.8311C8.43288 16.4718 9.91186 16.6582 11.3321 16.3607C12.7524 16.0632 14.0323 15.2989 14.9678 14.1896C15.9033 13.0804 16.4406 11.6899 16.4941 10.2398C16.5476 8.78964 16.1142 7.36334 15.2631 6.18811C14.4119 5.01288 13.1918 4.15635 11.7973 3.75499Z" fill="#1C78D3" />
                        <path d="M10.479 6.44287C10.479 6.02866 10.1432 5.69287 9.72898 5.69287C9.31476 5.69287 8.97898 6.02866 8.97898 6.44287V9.56622C8.97898 10.3158 9.39813 11.0025 10.0648 11.3451L12.7575 12.7288C13.1259 12.9181 13.578 12.773 13.7674 12.4045C13.9567 12.0361 13.8115 11.584 13.4431 11.3947L10.7504 10.0109C10.5838 9.92528 10.479 9.75362 10.479 9.56622V6.44287Z" fill="#1C78D3" />
                    </svg>

                    {t('history')}
                </StyledCardHeader>

                <Card.Content description>
                    <List divided relaxed>
                        {history.map((item, i) => (
                            <List.Item key={i}>
                                <List.Content>
                                    <List.Description>{dateToUTC(item.createdAt, 'DD.MM.YYYY HH:mm')}</List.Description>
                                    <List.Header>{item.userName}</List.Header>
                                    <List.Description>{item.message}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </Card.Content>
            </Card>

            <div className="card-actions-panel" style={{ left: 0 }}>
                <div>
                    {actionsFooter()}
                </div>
            </div>
        </Form>
    );
};

export default ShippingCard;
