export const PERSONAL_CABINET = 'personalCabinet';
export const PERSONAL_CABINET_HOME = 'home';
export const PERSONAL_CABINET_KPI = 'kpiAnalysis';
export const PERSONAL_CABINET_LATENESS = 'lates';
export const PERSONAL_CABINET_MEETING_PROTOCOLS = 'meetingProtocols';
export const PERSONAL_CABINET_TURNOVER = 'turnover';
export const PERSONAL_CABINET_CONTACTS_FM = 'fMContacts';
export const PERSONAL_CABINET_AVAILABLE_PARK = 'availablePark';
export const PERSONAL_CABINET_ADDITIONAL_INFORMATION = 'additionalInformation';
export const PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS = 'documentsAndInstructions';

export const fmContactsColumns = [
    {
        name: 'fullName',
        displayNameKey: 'fullName',
        notFilter: true,
        noSort: true
    },
    {
        name: 'post',
        displayNameKey: 'post',
        notFilter: true,
        noSort: true,
    },
    {
        name: 'responsibilityArea',
        displayNameKey: 'responsibilityArea',
        notFilter: true,
        noSort: true
    },
    {
        name: 'phoneNumber',
        displayNameKey: 'phoneNumber',
        notFilter: true,
        noSort: true,
    },
    {
        name: 'additionalPhoneNumber',
        displayNameKey: 'additionalPhoneNumber',
        notFilter: true,
        noSort: true,
    },
    {
        name: 'email',
        displayNameKey: 'email',
        notFilter: true,
        noSort: true,
    },
    {
        name: 'comment',
        displayNameKey: 'comment',
        notFilter: true,
        noSort: true
    },
    {
        name: 'subsidiaryId',
        displayNameKey: 'subsidiaryId',
        notFilter: true,
        noSort: true
    },
    {
        name: 'departmentId',
        displayNameKey: 'departmentId',
        notFilter: true,
        noSort: true
    },
    {
        name: 'subsidiaryAddress',
        displayNameKey: 'subsidiaryAddress',
        notFilter: true,
        noSort: true
    }
];