import React from 'react';
import {Form, Segment} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from "react-redux";
import {downloadApi} from "../../ducks/documents";

const Links = ({
                   value = [],
                   name,
                   isRequired,
                   error,
                   noLabel
               }) => {
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const hostname = window.location.hostname;
    const download = (link) => dispatch(downloadApi(link));

    return (
        <Form.Field>
            {!noLabel && <label>
                <span dangerouslySetInnerHTML={{__html: `${t(name)}${isRequired ? ' *' : ''}`}}/>
            </label>}
            <Segment className='links-area'>
                {
                    Array.isArray(value) ? value.map(link => <div><a href={!link.includes(hostname) && link}
                                                                     onClick={() => link.includes(hostname) && download(link)}>{link}</a>
                    </div>) : value
                }
            </Segment>
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{__html: error}}/>
            ) : null}
        </Form.Field>
    );
};
export default Links;
