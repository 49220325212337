import React, {useEffect} from 'react';
import {Form, Radio} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {
    getLookupRequest,
    valuesListSelector
} from "../../ducks/lookup";

const RadioButtons = ({
                          value,
                          name,
                          isDisabled,
                          onChange,
                          source,
                          isRequired,
                          error,
                          isMultiSelect = false,
                          noLabel = false,
                      }) => {
    const {t} = useTranslation();

    const handleChange = (e, itemValue) => {
        let values = !isMultiSelect ? itemValue : (value || []).includes(itemValue) ? value.filter(v => v !== itemValue) : [...(value || []), itemValue];
        onChange(e, {
            value: values,
            name: name
        });
    }

    let valuesList = useSelector(state => valuesListSelector(state, source)) || [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                isState: !isMultiSelect,
            }),
        );
    }, []);

    const disabled = typeof isDisabled !== 'object' ? isDisabled : false;

    const disabledItem = (name) => {
        if (typeof isDisabled === 'object') return isDisabled[name]
        else return isDisabled;
    }

    return (
        <Form.Field inline className='radio-buttons'>
            {!noLabel && <label className={disabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(name) + ':'}${isRequired ? ' *' : ''}`,
                        }}
                    />
            </label>}
            <span className='radio-buttons_items'>
                {
                    valuesList.map(item => (
                            <Radio
                                key={item.value}
                                error={error}
                                type='checkbox'
                                label={t(item.name || item.value)}
                                value={true}
                                checked={isMultiSelect ? (value || []).includes(item.value) : value === item.value}
                                disabled={disabled || disabledItem(item.value)}
                                onChange={(e)=>!isMultiSelect && handleChange(e, item.value)}
                                onClick={(e)=>isMultiSelect && handleChange(e, item.value)}
                            />
                        )
                    )
                }
            </span>
        </Form.Field>
    );
};
export default RadioButtons;
