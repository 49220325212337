import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Modal} from "semantic-ui-react";
import FormField from "../../../../components/BaseComponents";
import {useDispatch} from "react-redux";
import {setDriverInn} from "../../../../ducks/gridCard";

const DriverInnModal = ({open = false, error, onClose, driverId, innRequired = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [inn, setInn] = useState('');

    const changeInn = (e, {value}) => {
        if (/^\d+$/.test(value) || !value.length) setInn(value);
    };

    const saveInn = () => {
        dispatch(setDriverInn({inn, driverId, callbackSuccess: onClose}));
    }

    useEffect(()=>setInn(''), [open]);

    return (
        <Modal
            open={open}
            closeIcon
            size={'small'}
            onClose={onClose}
        >
            <Modal.Header>{t('enteringDriverInn')}</Modal.Header>
            <Modal.Content>
                <p>{t('enteringDriverInnText')}</p>
                <Form style={{textAlign: 'center'}}>
                    <Form.Group inline>
                        <FormField
                            maxLength={12}
                            text={t("driverInn")}
                            name="inn"
                            value={inn}
                            error={error['inn']}
                            onChange={changeInn}
                            width={'100%'}
                        />
                    </Form.Group>
                </Form>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} disabled={innRequired}>{t('enterLater')}</Button>
                <Button primary onClick={saveInn}>{t('SaveButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DriverInnModal;
