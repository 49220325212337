import React from 'react';
import {useTranslation} from "react-i18next";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {monthsShortNames, months} from "../../../constants/months";
import {Loader, Segment} from "semantic-ui-react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const Graph = ({yearsData, loading}) => {
    const {t} = useTranslation();

    const labels = monthsShortNames;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            tooltip: {
                enabled: false,
                external: (context) => {
                    let tooltipEl = document.getElementById('chartjs-tooltip-graph');

                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip-graph';
                        tooltipEl.innerHTML = '<div class="chart-tooltip__name"></div><div class="chart-tooltip__description"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();
                    let positionLeft = position.left + window.pageXOffset + tooltipModel.caretX + 6;

                    if (tooltipModel.dataPoints) {
                        const data = tooltipModel.dataPoints[0];

                        const tooltipPositionIsLeft = (window.innerWidth - positionLeft) < 130;

                        if (tooltipPositionIsLeft) {
                            tooltipEl.style.left = 'auto';
                            tooltipEl.style.right = (window.innerWidth - positionLeft + 12) + 'px';
                        } else {
                            tooltipEl.style.left = positionLeft + 'px';
                            tooltipEl.style.right = 'auto';
                        }

                        tooltipEl.className = `chart-tooltip chart-tooltip_graph-${data.datasetIndex} ${tooltipPositionIsLeft ? 'chart-tooltip_position_left' : ''}`;
                        tooltipEl.querySelector('.chart-tooltip__name').innerHTML = `${t(months[data.dataIndex])} ${data.dataset.label}`;
                        tooltipEl.querySelector('.chart-tooltip__description').innerHTML = `<b></b>`;
                        if (tooltipEl.querySelector('b')) tooltipEl.querySelector('b').innerHTML = `${data.formattedValue} ${t('mln-rub')}.`;
                    }

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 23 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
        },
        scales: {
            y: {
                min: 0,
                grid: {
                    borderDash: [3],
                },
            },
        },
        elements: {
            line: {
                borderWidth: 2
            },
            point: {
                radius: 1,
                hoverRadius: 4,
                hoverBackgroundColor: (ctx) => {
                    return ctx.element.options.borderColor
                }
            }
        }
    };

    const data = {
        labels: labels.map(l => t(l).toUpperCase()),
        datasets: !yearsData ? [] : [
            {
                fill: true,
                label: yearsData.toYear.year,
                data: yearsData.toYear.turnoverArray,
                borderColor: '#034EA2',
                backgroundColor: 'rgba(3, 78, 162, 0.2)',
            },
            {
                fill: true,
                label: yearsData.fromYear.year,
                data: yearsData.fromYear.turnoverArray,
                borderColor: '#92E72B',
                backgroundColor: 'rgba(184, 250, 196, 0.3)',
            }
        ],
    };

    return (
        <Segment vertical>
            <Loader active={loading}/>
            <div className='styled-block-stat years-graph'>
                <h3>
                    {t('turnoverCompany')} <span>({t('mln-rub')})</span>
                </h3>
                {yearsData && <div className='years-graph__legend'>
                    <div>{yearsData.fromYear.year}</div>
                    <div>{yearsData.toYear.year}</div>
                </div>}
                {!loading && !yearsData && <h4>{t('noData')}</h4>}
                <div className='years-graph__line'>
                    <Line
                        options={options}
                        data={data}
                    />
                </div>
            </div>
        </Segment>
    );
};

export default Graph;