import React, { Component } from 'react';
import { connect } from 'react-redux';
import SuperGrid from '../../components/SuperGrid';
import {
    autoUpdateStart,
    autoUpdateStop,
    canCreateByFormSelector,
    clearGridInfo,
    getListRequest,
    listSelector,
    modesFromGridSelector,
    progressSelector,
    totalCountSelector,
    unloadRegistries,
} from '../../ducks/gridList';
import { withRouter } from 'react-router-dom';
import Card from './card';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import {
    actionsSelector, allIdsSelector, clearAllIdsRequest,
    getActionsRequest,
    getAllIdsRequest,
    invokeActionRequest,
} from '../../ducks/gridActions';
import {
    editDefaultRepresentationsRequest,
    editRepresentationRequest,
    editZonesFilter,
    getRepresentationsRequest,
    representationFromGridSelector,
    representationNameSelector,
    representationsIsLockedSelector,
    zonesFilterSelector,
} from '../../ducks/representations';
import {
    GRID_CARD_LINK,
    GRID_NEW_LINK,
} from '../../router/links';
import {startAutoUpdateConfigRequest, stopAutoUpdateConfigRequest} from "../../ducks/profile";
import BannersBlock from "../../components/Banners/BannersBlock";
import {FREE_TRANSPORTS_GRID, REGISTRATION_TC_GRID, SHIPPINGS_GRID} from "../../constants/grids";
import {filtersFromSearch} from "../../utils/urlParamsHelper";

const CreateButton = ({ t, ...res }) => {
    return (
        <Card {...res}>
            <Button icon="add" />
        </Card>
    );
};

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmation: { open: false },
            heightBannersBlock: 0
        };
    }

    componentWillUnmount() {
        this.props.stopUpdate({isClear: true});
        this.props.stopConfigUpdate();
    }

    mapActions = (item, t, invokeAction, name) => ({
        ...item,
        name: `${t(item.name)}`,
        action: (rows, clearSelectedRows) => {
            this.showConfirmation(
                `${t('Are you sure to complete')} "${t(item.name)}" ${
                    rows.length > 1 ? `${t('for')} ` + rows.length : ''
                }?`,
                () => {
                    this.closeConfirmation();
                    invokeAction({
                        ids: rows,
                        name,
                        actionName: item.name,
                        redirectCallback: clearSelectedRows,
                        callbackSuccess: () => {
                            clearSelectedRows();
                        },
                    });
                },
            );
        },
    });

    getGroupActions = () => {
        const { t, actions, invokeAction, match, nameReport } = this.props;
        const { params = {} } = match;
        const { name = nameReport || '' } = params;
        const actionsFromGrid = actions.filter(item => item.allowedFromGrid);

        let obj = {
            require: actionsFromGrid
                .filter((item, index) => index < 3)
                .map(item => this.mapActions(item, t, invokeAction, name)),
            other: actionsFromGrid
                .filter((item, index) => index >= 3)
                .map(item => this.mapActions(item, t, invokeAction, name)),
            order: actionsFromGrid
                .filter((item, index) => index >= 3)
                .filter(item => item.group === 'Order')
                .map(item => this.mapActions(item, t, invokeAction, name)),
            shipping: actionsFromGrid
                .filter((item, index) => index >= 3)
                .filter(item => item.group === 'Shipping')
                .map(item => this.mapActions(item, t, invokeAction, name)),
        };

        return obj;
    };

    showConfirmation = (content, onConfirm) => {
        this.setState({ confirmation: { open: true, onConfirm, content } });
    };

    closeConfirmation = () => {
        this.setState({ confirmation: { open: false } });
    };

    modalCard = () => {
        const { stopUpdate, match = {}, nameReport } = this.props;
        const { params = {} } = match;
        const { name = nameReport || '' } = params;
        return <Card stopUpdate={stopUpdate} name={name} />;
    };

    render() {
        const {
            columns = [],
            autoUpdate,
            stopUpdate,
            list,
            totalCount,
            progress,
            match = {},
            t,
            isCreateBtn,
            getActions,
            getAllIds,
            editRepresentation,
            representationName,
            getRepresentations,
            startConfigUpdate,
            stopConfigUpdate,
            allIds,
            clearAllIds,
            modes,
            editDefaultRepresentations,
            isReport = false,
            topBlockHeight = 0,
            nameReport,
            getList,
            filters = {},
            trigger,
            clickReport,
            actionBtns,
            cardLink,
            editableColumns,
            clearGridInfo,
            isShowActions,
            infinityScroll,
            reportWithCheckbox,
            generateBtn,
            isRefresh,
            unloadRegistries,
            zonesFilter,
            setZonesFilter,
            representationsIsLocked,
            noRepresentation = false,
            noModes: modesHidden,
            noFooter: footerHidden,
            customBtns,
            newLink,
            preFilters,
            className = '',
            cardLinkFunc,
            useBigLoader
        } = this.props;

        const { params = {} } = match;
        const { name = nameReport || '' } = params;
        const { confirmation } = this.state;
        const openCardByCellClickGrids = [SHIPPINGS_GRID, FREE_TRANSPORTS_GRID];
        const checkIsAvailable = [FREE_TRANSPORTS_GRID].includes(name);
        const noFooter = footerHidden || [FREE_TRANSPORTS_GRID].includes(name);
        const noModes = modesHidden || [REGISTRATION_TC_GRID, FREE_TRANSPORTS_GRID].includes(name);

        return (
            <div className={`${(!isReport || generateBtn) ? 'container' : 'container__report'} ${className}`}>
                {
                    name === SHIPPINGS_GRID && <BannersBlock setHeight={(h)=>this.setState({heightBannersBlock: h})}/>
                }
                <SuperGrid
                    useBigLoader={useBigLoader}
                    isShowActions={isShowActions}
                    actionBtns={actionBtns}
                    isReport={isReport}
                    isRefresh={isRefresh}
                    trigger={trigger}
                    filters={filters}
                    click={clickReport}
                    getList={getList}
                    key={name}
                    columns={columns}
                    rows={list}
                    name={name}
                    modes={modes}
                    editRepresentation={editRepresentation}
                    editDefaultRepresentations={editDefaultRepresentations}
                    representationName={representationName}
                    getRepresentations={getRepresentations}
                    autoUpdateStart={autoUpdate}
                    autoUpdateStop={stopUpdate}
                    totalCount={totalCount}
                    progress={progress}
                    storageSortItem={`${name}Sort`}
                    storageFilterItem={`${name}Filters`}
                    storageRepresentationItems={`${name}Representation`}
                    getActions={getActions}
                    groupActions={this.getGroupActions}
                    getAllIds={getAllIds}
                    modalCard={this.modalCard}
                    isCreateBtn={isCreateBtn}
                    confirmation={confirmation}
                    closeConfirmation={this.closeConfirmation}
                    newLink={isCreateBtn ? (newLink || GRID_NEW_LINK) : null}
                    cardLink={cardLink || GRID_CARD_LINK}
                    cardLinkFunc={cardLinkFunc}
                    startConfigUpdate={startConfigUpdate}
                    stopConfigUpdate={stopConfigUpdate}
                    allIds={allIds}
                    clearAllIds={clearAllIds}
                    bannerHeight={this.state.heightBannersBlock || topBlockHeight}
                    editableColumns={editableColumns}
                    clearGridInfo={clearGridInfo}
                    copyDisabled={isReport || name !== SHIPPINGS_GRID}
                    infinityScroll={infinityScroll}
                    reportWithCheckbox={reportWithCheckbox}
                    generateBtn={generateBtn}
                    unloadRegistries={unloadRegistries}
                    representationsIsLocked={representationsIsLocked}
                    zonesFilter={zonesFilter}
                    setZonesFilter={setZonesFilter}
                    noRepresentation={noRepresentation}
                    noModes={noModes}
                    noFooter={noFooter}
                    customBtns={customBtns}
                    preFilters={{...(preFilters || {}), ...filtersFromSearch(this.props.location)}}
                    openCardByCellClick={openCardByCellClickGrids.includes(name)}
                    checkIsAvailable={checkIsAvailable}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        autoUpdate: params => {
            dispatch(autoUpdateStart(params));
        },
        getList: params => {
            dispatch(getListRequest(params));
        },
        unloadRegistries: params => {
            dispatch(unloadRegistries(params));
        },
        stopUpdate: params => {
            dispatch(autoUpdateStop(params));
        },
        getActions: params => {
            dispatch(getActionsRequest(params));
        },
        invokeAction: params => {
            dispatch(invokeActionRequest(params));
        },
        getAllIds: params => {
            dispatch(getAllIdsRequest(params));
        },
        editRepresentation: params => {
            dispatch(editRepresentationRequest(params));
        },
        editDefaultRepresentations: params => {
            dispatch(editDefaultRepresentationsRequest(params))
        },
        getRepresentations: params => {
            dispatch(getRepresentationsRequest(params));
        },
        startConfigUpdate: () => {
            dispatch(startAutoUpdateConfigRequest())
        },
        stopConfigUpdate: () => {
            dispatch(stopAutoUpdateConfigRequest())
        },
        clearAllIds: params => {
            dispatch(clearAllIdsRequest(params))
        },
        setZonesFilter: params => {
            dispatch(editZonesFilter(params))
        },
        clearGridInfo: () => {
            dispatch(clearGridInfo())
        }
    };
};

const mapStateToProps = (state, ownProps) => {
    const { match = {}, nameReport } = ownProps;
    const { params = {} } = match;
    const { name = nameReport || '' } = params;

    return {
        columns: representationFromGridSelector(state, name),
        modes: modesFromGridSelector(state, name),
        list: listSelector(state),
        totalCount: totalCountSelector(state),
        progress: progressSelector(state),
        isCreateBtn: canCreateByFormSelector(state, name),
        actions: actionsSelector(state),
        representationName: representationNameSelector(state, name),
        allIds: allIdsSelector(state),
        representationsIsLocked: representationsIsLockedSelector(state, name),
        zonesFilter: zonesFilterSelector(state),
    };
};

export default withTranslation()(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(List),
    ),
);
