import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Confirm, Form, Modal} from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import {
    cardSelector,
    errorSelector,
    progressSelector,
    saveDictionaryCardRequest
} from '../../ducks/dictionaryView';
import {BIG_TEXT_TYPE, DATE_TYPE} from "../../constants/columnTypes";

const BannersCard = ({row = null, onClose: onCloseModal, openModal, load}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(()=>{
        setForm(row ? {...row} : {});
        setNotChangeForm(true);
    }, [row, openModal]);

    const name = 'banners';

    let [form, setForm] = useState({...row});
    let id = form.id;
    let [confirmation, setConfirmation] = useState({open: false});
    let [notChangeForm, setNotChangeForm] = useState(true);

    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const error = useSelector(state => errorSelector(state));

    useEffect(() => {
        setForm(form => ({
            ...card,
            ...form,
        }));
    }, [card]);

    const title = useMemo(() => (id ? t('edit-banner') : t('create-banner')), [id]);

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm}
                    loading={progress}
                    onClick={() => handleSave()}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form, notChangeForm, progress, id]);

    const handleSave = () => {
        let params = {
            ...form,
        };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                callbackSuccess: () => {
                    load && load();
                    onClose();
                },
            }),
        );
    };

    const handleChange = useCallback(
        (event, {name, value}) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            setForm(form => ({
                ...form,
                [name]: value,
            }));
        },
        [notChangeForm, form],
    );

    const confirmClose = () => {
        setConfirmation({open: false});
    };

    const onClose = () => {
        onCloseModal();
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: () => {
                    onClose();
                    confirmClose();
                },
            });
        }
    };

    return (
        <>
            <Modal
                dimmer="blurring"
                open={openModal}
                closeOnDimmerClick={false}
                onClose={onCloseModal}
                closeIcon
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Description>
                    <div className='banners-card__form'>
                        <Form>

                            <div className='banners-card__input'>
                                <FormField
                                    maxLength={100}
                                    text={t("titleBanner")}
                                    name="title"
                                    value={form['title']}
                                    isRequired
                                    error={error['title']}
                                    onChange={handleChange}
                                    width={'100%'}
                                />
                                <span className={'banners-card__input-lenght'}>
                                    {`${form['title'] ? form['title'].length : 0}/100`}
                                </span>
                            </div>

                            <div className='banners-card__input'>
                                <FormField
                                    type={BIG_TEXT_TYPE}
                                    maxLength={200}
                                    label={t("text")}
                                    name="text"
                                    value={form['text']}
                                    error={error['text']}
                                    onChange={handleChange}
                                    width={'100%'}
                                />
                                <span className={'banners-card__input-lenght'}>
                                    {`${form['text'] ? form['text'].length : 0}/200`}
                                </span>
                            </div>

                            <div className='banners-card__input'>
                                <FormField
                                    maxLength={80}
                                    label={t("buttonTitle")}
                                    name="buttonTitle"
                                    value={form['buttonTitle']}
                                    isRequired
                                    error={error['buttonTitle']}
                                    onChange={handleChange}
                                    width={'100%'}
                                />
                                <span className={'banners-card__input-lenght'}>
                                    {`${form['buttonTitle'] ? form['buttonTitle'].length : 0}/80`}
                                </span>
                            </div>

                            <div className='banners-card__input'>
                                <FormField
                                    maxLength={200}
                                    label={t("redirectUrl")}
                                    name="redirectUrl"
                                    value={form['redirectUrl']}
                                    error={error['redirectUrl']}
                                    onChange={handleChange}
                                    width={'100%'}
                                />
                            </div>

                            <Form.Group className='banners-card__fields' widths={2}>
                                <FormField
                                    type={DATE_TYPE}
                                    maxLength={200}
                                    label={t("showFrom")}
                                    name="showFrom"
                                    value={form['showFrom']}
                                    error={error['showFrom']}
                                    onChange={handleChange}
                                />

                                <FormField
                                    type={DATE_TYPE}
                                    maxLength={200}
                                    label={t("showTo")}
                                    name="showTo"
                                    value={form['showTo']}
                                    error={error['showTo']}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                        </Form>
                    </div>
                </Modal.Description>
                <Modal.Actions>{getActionsFooter()}</Modal.Actions>
            </Modal>
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </>
    );
};

export default BannersCard;
