import React from "react";
import { useTranslation } from "react-i18next";
import Information from "./regTransportCompanyTabs/information";
import { useSelector } from "react-redux";
import CardLayout from "../../../components/CardLayout";
import DocumentsRegTC from "./regTransportCompanyTabs/documents";
import History from "./shared/history";
import { SETTINGS_TYPE_HIDE } from "../../../constants/formTypes";
import { documentsTemplatesLoadingSelector } from "../../../ducks/documents";
import Training from "./regTransportCompanyTabs/training";
import InitiatorContactData from "./regTransportCompanyTabs/initiatorContactData";

const RegistrationTCCard = ({
    form,
    onChangeForm,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
    updateHistory,
    isRegTC = false,
    scrollTo = null,
}) => {
    const { t } = useTranslation();

    const canScroll = !useSelector((state) =>
        documentsTemplatesLoadingSelector(state)
    );

    const getPanes = () => {
        const obj = [
            {
                menuItem: t("applicationForm"),
                render: () => (
                    <Information
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        updateHistory={!isRegTC && updateHistory}
                        isRegTC={isRegTC}
                    />
                ),
            },
            {
                menuItem: t("documents"),
                disabled: false,
                isOpen: !(
                    settings && settings["documents"] === SETTINGS_TYPE_HIDE
                ),
                render: () => (
                    <DocumentsRegTC
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        updateHistory={!isRegTC && updateHistory}
                        isRegTC={isRegTC}
                    />
                ),
            },
        ];

        if (form.isEducationVisiable)
            obj.splice(1, 0, {
                menuItem: t("training"),
                render: () => <Training form={form} />,
            });

        if (!isRegTC)
            obj.push({
                menuItem: t("history"),
                render: () => <History cardId={id} status={form.status} />,
            });

        if (!isRegTC)
            obj.unshift({
                menuItem: t("registrationTcRequest.initiatorContactData"),
                render: () => (
                    <InitiatorContactData
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                ),
            });

        return obj;
    };

    return (
        <>
            <CardLayout
                title={title}
                actionsFooter={actionsFooter}
                actionsHeader={actionsHeader}
                content={getPanes}
                onClose={onClose}
                loading={loading}
                scrollTo={canScroll && scrollTo}
            />
        </>
    );
};

export default RegistrationTCCard;
