import React from 'react';
import {Segment, Dimmer, Loader, Icon} from "semantic-ui-react";

const FileBlock = ({fileName, fileId, fileDescription, fileDownload, loading}) => {
    return (
        <Segment className='file-view' key={fileId}>
            <Dimmer inverted active={loading}>
                <Loader/>
            </Dimmer>
            <div>
                <label>{fileName}</label>
                <div className='description'>{fileDescription}</div>
                <div onClick={() => fileDownload(fileId)} className='file-item file-item-add file-view__icon'>
                    <Icon name='file outline'/>
                </div>
            </div>
        </Segment>
    );
};

export default FileBlock;