export const OPERATIONAL_REPORT_PERMISSION = 27;
export const DOCS_RETURN_REPORT_SHOW_PERMISSION = 54;
export const BANNERS_PERMISSION_CODE = 47;
export const REGISTRIES_FOR_PAYMENT_SHOW_PERMISSION = 57;
export const REGISTRIES_FOR_PAYMENT_EDIT_PERMISSION = 58;
export const PERSONAL_CABINET_PERMISSION = 'personalCabinet';
export const ADMIN_TEMPLATES_PERMISSION = 'adminTemplates';
export const PROFILE_PERMISSION = 'profile';
export const EDIT_USER_PERMISSION = 'editUsers';
export const EDIT_ROLES_PERMISSION = 'editRoles';
export const BANNERS_PERMISSION = 'banners';
