import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    Modal, Card, Button, Icon,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import { formatDate } from '../../../../utils/dateTimeFormater';

export const StyledModal = styled(Modal)`
    && {
        height: 95%;
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }
`;

export const StyledModalHeader = styled(Modal.Header)`
    text-align: center;
    font-size: 16px;
    padding: 25px 0;
`;

export const StyledModalContent = styled(Modal.Content)`
    flex-grow: 1;
`;

const defaultDates = [new Date(), null];

const CalendarModal = ({
    title, iconName, visible, onClose, onConfirm,
}) => {
    const { t } = useTranslation();
    const [dates, setDates] = useState(defaultDates);

    const onChange = (newDates) => {
        setDates(newDates);
    };

    const reset = () => {
        setDates(defaultDates);
    };

    const confirm = (e) => {
        e.stopPropagation();

        onConfirm(dates.map((date) => formatDate(date)));
    };

    return (
        <StyledModal open={visible} onClose={onClose} size="fullscreen" closeIcon>
            <StyledModalHeader content={t('filters')} />

            <StyledModalContent>
                <Card fluid>
                    <Card.Content>
                        <Card.Header>
                            <Icon name={iconName} style={{ marginRight: '8px' }} />

                            {title}
                        </Card.Header>
                    </Card.Content>

                    <Card.Content>
                        <DatePicker
                            selected={dates[0]}
                            onChange={onChange}
                            startDate={dates[0]}
                            endDate={dates[1]}
                            locale={localStorage.getItem('i18nextLng')}
                            dateFormat="dd.MM.yyyy"
                            selectsRange
                            inline
                            showDisabledMonthNavigation
                        />
                        <p>
                            {t('selectedDates')}
                            {dates.map((date) => formatDate(date)).join(' - ')}
                        </p>
                    </Card.Content>
                </Card>
            </StyledModalContent>

            <Modal.Actions>
                <Button style={{ marginLeft: '0' }} fluid onClick={reset}>
                    {t('resetAll')}
                </Button>

                <Button color="blue" style={{ marginLeft: '0' }} fluid onClick={confirm}>
                    {t('applyBtn')}
                </Button>
            </Modal.Actions>
        </StyledModal>
    );
};

export default CalendarModal;
