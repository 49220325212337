import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthSelector } from '../ducks/login';
import {
    gridsMenuSelector,
    homePageSelector,
} from '../ducks/profile';
import {REGISTRATION_TC_GRID} from "../constants/grids";

function PrivateRegRoute({ component: Component, ...rest }) {
    const isAuth = useSelector(state => isAuthSelector(state));
    const homePage = useSelector(state => homePageSelector(state));
    const gridsMenu = useSelector(state => gridsMenuSelector(state)) || [];

    const { computedMatch, location } = rest;
    const { params } = computedMatch;
    const { hash } = location;

    return (
        <Route
            {...rest}
            render={props =>
                !isAuth ? (
                    <Component {...props} />
                ) : (
                    gridsMenu.includes(REGISTRATION_TC_GRID) ?
                    <Redirect
                        to={{
                            pathname: `/grid/${REGISTRATION_TC_GRID}/${params.id}`,
                            hash
                        }}
                    />
                    : <Redirect
                        to={{
                            pathname: homePage,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRegRoute;
