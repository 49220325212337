import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {
    TEXT_TYPE,
    BIG_TEXT_TYPE,
    DATE_TIME_TYPE,
    DATE_TYPE,
    NUMBER_TYPE,
    STATE_TYPE,
} from '../../../../constants/columnTypes';
import { settingsExtSelector } from '../../../../ducks/gridCard';

const StopperDocument = ({name, form = {}, stopperDocument = {}, index, settings: baseSettings, }) => {

    const {t} = useTranslation();

    return (
        <Form>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <hr />
                        <b>{t('stopperDocuments_caption')} "{stopperDocument['clientName']}"</b>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <FormField
                            isReadOnly
                            name="wno"
                            text="stopperDocuments_wno"
                            value={stopperDocument['wno']}
                            type={BIG_TEXT_TYPE}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column>
                        <FormField
                            isReadOnly
                            name="cno"
                            text="stopperDocuments_cno"
                            value={stopperDocument['cno']}
                            type={BIG_TEXT_TYPE}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column>
                        <FormField
                            isReadOnly
                            name="torg12"
                            text="stopperDocuments_torg12"
                            value={stopperDocument['torg12']}
                            type={BIG_TEXT_TYPE}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column>
                        <FormField
                            isReadOnly
                            name="docsCompleteness"
                            text="stopperDocuments_docsCompleteness"
                            value={stopperDocument['docsCompleteness']}
                            type={BIG_TEXT_TYPE}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default StopperDocument;
