import React from 'react';
import PersonalCabinetMenu from "./menu";
import './styles.scss';
import {useDispatch, useSelector} from "react-redux";
import {paramsSelector, setParams} from "../../ducks/personalCabinet";

const PersonalCabinet = ({component: Component, ...props}) => {

    const params = useSelector(paramsSelector);
    const dispatch = useDispatch();

    const setNewParams = (params) => {
        dispatch(setParams(params));
    };

    return (
        <div className='personal-cabinet'>
            <PersonalCabinetMenu params={params} activeItem={props.activeItem}/>
            <Component params={params} setParams={setNewParams} {...props} />
        </div>
    );
};

export default PersonalCabinet;
