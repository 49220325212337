export default (width, count, name) => {
    const w = widthByName(name);
    return parseInt(width / count) > w
        ? parseInt(width / count)
        : w
}

function findLongestWord(str) {
    return str.split(/\s+/)
        .reduce(function(longest, word) {
            return word.length > longest.length ? word : longest;
        }, "");
}

export const widthByName = (name) => {
    const minWidth = 100;
    if (name) {
        const w = findLongestWord(name).length * 12;
        return w > minWidth ? w : minWidth
    } else return minWidth;
}