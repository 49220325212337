import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    List,
    Label,
    Modal,
    Input,
    Button,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { getLookupRequest, listFromMultiSelect } from '../../../../ducks/lookup';
import Icon from '../../../../components/CustomIcon';
import DropdownModal from '../DropdownModal/DropdownModal';
import CalendarModal from '../CalendarModal';
import {
    StyledSpace,
    StyledModal,
    StyledModalHeader,
    StyledModalContent,
    StyledListItem,
    StyledListDescription,
    StyledListContent,
    StyledListHeader,
    StyledLabelContainer,
} from './styles';
import InputModal from '../InputModal';

const defaultFilters = {
    search: '',
};

const WrappedListItem = ({
    kind, name, source, title, icon, getSelectedItems, selectedItemsIds, onConfirm, params,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const options = useSelector((state) => listFromMultiSelect(state, source || 'list', true, t));
    const [visible, setVisible] = useState(false);

    const selectedItems = getSelectedItems(name, options);

    const handleOpen = () => {
        dispatch(
            getLookupRequest({
                name: source || name,
                ...params,
            }),
        );
    };

    const fields = {
        select: (
            <DropdownModal
                title={title}
                iconName={icon}
                visible={visible}
                options={options}
                onClose={(e) => {
                    e.stopPropagation();
                    setVisible(false);
                }}
                selectedItemsIds={selectedItemsIds}
                onMaskClick={() => setVisible(false)}
                onConfirm={(value) => {
                    setVisible(false);
                    onConfirm(name, value);
                }}
                onOpen={handleOpen}
            />
        ),
        calendar: (
            <CalendarModal
                title={title}
                iconName={icon}
                visible={visible}
                onClose={(e) => {
                    e.stopPropagation();
                    setVisible(false);
                }}
                onConfirm={(value) => {
                    setVisible(false);
                    onConfirm(name, value);
                }}
            />
        ),
        text: (
            <InputModal
                defaultValue={selectedItemsIds}
                title={title}
                iconName={icon}
                visible={visible}
                onClose={(e) => {
                    e.stopPropagation();
                    setVisible(false);
                }}
                onConfirm={(value) => {
                    setVisible(false);
                    onConfirm(name, value);
                }}
            />
        ),
    };

    return (
        <StyledListItem onClick={() => setVisible(true)}>
            <StyledListContent>
                <StyledListHeader>
                    <div>
                        <List.Icon color="blue" name={icon} />

                        <span style={{ fontSize: '16px' }}>{title}</span>
                    </div>

                    <List.Icon name="angle right" />
                </StyledListHeader>

                {fields[kind]}

                {!!selectedItems?.length && (
                    <StyledListDescription>
                        <StyledLabelContainer>
                            {selectedItems.map((item) => <Label key={item}>{item}</Label>)}
                        </StyledLabelContainer>
                    </StyledListDescription>
                )}
            </StyledListContent>
        </StyledListItem>
    );
};

const Filters = ({ onChange, onReset, columns = [] }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);

    const isFiltered = useMemo(() => (
        Object.entries(filters)
            .some(([, value]) => !!value?.length)
    ), [filters]);

    const handleConfirm = (name, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleSearch = (event, { value }) => {
        setFilters((prevFilters) => ({ ...prevFilters, search: value }));

        onChange({ search: value });
    };

    const handleFormConfirm = () => {
        onChange(filters);
        setOpen(false);
    };

    const resetFilters = () => {
        setFilters(defaultFilters);
        onReset(defaultFilters);
        setOpen(false);
    };

    const getSelectedItems = (name, options) => options?.filter((option) => (filters[name] || []).includes(option.value)).map((option) => option.text);

    return (
        <StyledSpace justify="between" block>
            <Input
                fluid
                icon="search"
                iconPosition="left"
                placeholder={t('search_all_fields')}
                onChange={handleSearch}
            />

            {isFiltered && (
                <Button
                    icon
                    className="clear-filter-btn"
                    onClick={resetFilters}
                >
                    <Icon name="clear-filter" />
                </Button>
            )}

            <StyledModal
                closeIcon
                size="fullscreen"
                open={open}
                trigger={<Button style={{ backgroundColor: '#1C78D3', color: 'white' }} icon="filter" />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <StyledModalHeader content={t('filters')} />

                <StyledModalContent>
                    <List celled>
                        {columns.map((column) => (
                            <WrappedListItem
                                kind={column.kind}
                                name={column.name}
                                source={column.source}
                                title={t(column.title)}
                                icon={column.icon}
                                params={column.params}
                                getSelectedItems={column.getSelectedItems?.(filters[column.name]) || getSelectedItems}
                                selectedItemsIds={filters[column.name]}
                                onConfirm={handleConfirm}
                            />
                        ))}
                    </List>
                </StyledModalContent>

                <Modal.Actions>
                    <Button style={{ marginLeft: '0' }} fluid onClick={resetFilters}>
                        {t('resetAllFilters')}
                    </Button>

                    <Button style={{ marginLeft: '0', backgroundColor: '#1C78D3' }} inverted fluid onClick={handleFormConfirm}>
                        {t('applyBtn')}
                    </Button>
                </Modal.Actions>
            </StyledModal>
        </StyledSpace>
    );
};

export default Filters;
