import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import TableInfo from '../../components/TableInfo';
import {useTranslation} from 'react-i18next';
import {Confirm, Icon, Popup} from 'semantic-ui-react';
import {
    bannersListSelector,
    getListRequest,
    saveDictionaryCardRequest,
    totalCountSelector,
    progressSelector,
    deleteDictionaryEntryRequest,
    canExportToExcelSelector,
    exportToExcelRequest
} from "../../ducks/dictionaryView";
import {bannersColumns} from "../../constants/bannersColumns";
import BannersCard from "./banners_card";

const BannersList = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector(state => progressSelector(state));
    const list = useSelector(state => bannersListSelector(state));
    const totalCount = useSelector(state => totalCountSelector(state));
    const [showModal, setShowModal] = useState(false);
    const [confirmation, setConfirmation] = useState({open: false});
    const [row, setRow] = useState(false);
    const [triggerLoad, setTriggerLoad] = useState(false);

    const onShowModal = (row) => {
        setRow(row);
        setShowModal(true);
    }

    const onCloseModal = () => {
        setRow(null);
        setShowModal(false);
    }

    const handleToggleIsActive = (itemID, checked, load) => {
        let row = list.find(row => row.id === itemID);
        dispatch(
            saveDictionaryCardRequest({
                name: 'banners',
                params: {
                    ...row,
                    isActive: checked
                },
                callbackSuccess: () => {
                    load(false, true);
                },
            }),
        );
    };

    const loadList = (params) => {
        dispatch(getListRequest(params));
    }

    const onClose = () => {
        setConfirmation({open: false});
        setTriggerLoad(tr => !tr);
    }

    const actions = (row) => {
        return [
            <Popup
                content={t('edit')}
                position="left center"
                trigger={<Icon name='pencil alternate' onClick={() => onShowModal(row)}/>}
            />,
            <Popup
                content={t('delete')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='trash alternate'
                        onClick={() => setConfirmation({
                            open: true,
                            content: t('Delete dictionary entry', { name: t('banners') }),
                            cancel: onClose,
                            confirm: () => dispatch(
                                deleteDictionaryEntryRequest({
                                    name: 'banners',
                                    id: row.id,
                                    callbackSuccess: onClose,
                                }),
                            )
                        })}
                    />
                }
            />
        ]
    }

    const handleExportToExcel = filter => {
        dispatch(exportToExcelRequest({
            name: 'banners',
            filter,
        }));
    };

    return (
        <>
            <TableInfo
                isExportBtn
                exportToExcel={handleExportToExcel}
                headerRow={bannersColumns}
                name="banners"
                loading={loading}
                className="container"
                list={list}
                toggleIsActive={handleToggleIsActive}
                totalCount={totalCount}
                loadList={loadList}
                newLink={null}
                newOnClick={() => setShowModal(true)}
                cardLink={null}
                isShowActions
                actions={actions}
                triggerLoad={triggerLoad}
            />
            <BannersCard
                row={row}
                openModal={showModal}
                onClose={onCloseModal}
                load={() => setTriggerLoad(tr => !tr)}
            />
            <Confirm
                open={confirmation.open}
                content={confirmation.content}
                onCancel={confirmation.cancel}
                cancelButton={t('cancelConfirm')}
                onConfirm={confirmation.confirm}
            />
        </>

    )
}

export default BannersList;