import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Confirm, Dimmer, Loader, Modal, Popup} from 'semantic-ui-react';
import {useDispatch, useSelector} from "react-redux";
import {
    anomalyPhotosLoadingSelector,
    anomalyPhotosSelector, deleteAnomalyPhoto,
    getAnomalyPhotos,
    newAnomalyPhoto
} from "../../../../ducks/gridCard";
import Documents from "../shared/documents";

const AnomalyPhotos = ({id, disabled, disabledAdd}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [confirmation, setConfirmation] = useState({open: false});

    const photos = useSelector(state => anomalyPhotosSelector(state));
    const loading = useSelector(state => anomalyPhotosLoadingSelector(state));

    const dispatch = useDispatch();

    useEffect(() => {
        updateData();
    }, [id, open]);

    const updateData = () => {
        if (open && id) dispatch(getAnomalyPhotos(id));
    }

    const getPhoto = (link) => {
        return `${window.location.origin}/api/anomalyDocuments?id=${link}`;
    }

    const onConfirm = (id) => {
        dispatch(deleteAnomalyPhoto({
            id: id,
            callbackSuccess: updateData
        }));
        setConfirmation({open: false})
    }

    const deletePhoto = (id) => {
        setConfirmation({
            open: true,
            content: t('delete-photo'),
            onCancel: () => setConfirmation({open: false}),
            onConfirm: () => onConfirm(id),
        })
    }

    return (
        <div>
            <Popup
                content={t('photos')}
                position="bottom right"
                trigger={
                    <Button icon='images' disabled={disabled} onClick={() => setOpen(true)}/>
                }
            />

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeIcon
            >
                <Modal.Header>
                    {t('photos')}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Dimmer active={loading} inverted>
                        <Loader size="large">Loading</Loader>
                    </Dimmer>
                    {
                        photos.length ? photos.map(link => (
                            <span className='photo-anomaly' key={link.fileLink}>
                                <a href={link.fileId ? getPhoto(link.fileLink) : link.fileLink} target="new">
                                    <img className='photo-anomaly__img'
                                         src={link.fileId ? getPhoto(link.fileLink) : link.fileLink} alt='img'
                                         width={190} height={190}
                                         style={{objectFit: 'cover'}}/>
                                </a>
                                <Button color='black' className='photo-anomaly__delete-btn' icon='delete'
                                        disabled={!link.canDelete || disabledAdd} onClick={() => deletePhoto(link.fileLink)}/>
                            </span>
                        )) : <div className='photo-anomaly__no-photo'>{t('noPhotos')}</div>
                    }

                    <div style={{marginLeft: '10px'}}>
                        {!disabledAdd && <Documents
                            id={id}
                            types={['image/png', 'image/jpg', 'image/jpeg']}
                            isBtn
                            btnDisabledPopupText={'anomalyMaxPhotosCount'}
                            btnPopupText={'addPhoto'}
                            uploadFile={newAnomalyPhoto}
                            isEditPermissions={photos.length < 30}
                            updateHistory={updateData}
                        />}
                    </div>
                </Modal.Content>
            </Modal>

            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </div>
    );
};

export default AnomalyPhotos;
