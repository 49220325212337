import React from 'react';
import FormField from "../formField";
import {Form} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {columnsFormSelector} from "../../../../ducks/gridList";
import {REGISTRATION_TC_GRID} from "../../../../constants/grids";

const InitiatorContactData = ({form, onChange, settings, error}) => {
    const allFields = useSelector(state => columnsFormSelector(state, REGISTRATION_TC_GRID));
    const fieldNamesArray = ['initiatorFullName', 'initiatorPhone', 'initiatorEmail']
    const fieldsArray = allFields.filter(f => fieldNamesArray.includes(f.name));

    return (
        <Form className="dictionary-edit padding-14">
            {
                fieldsArray.map(field => (
                    <FormField
                        {...field}
                        form={form}
                        isRequired
                        onChange={onChange}
                        settings={settings}
                        error={error}
                    />
                ))
            }
        </Form>
    );
};

export default InitiatorContactData;