import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getPaymentTerms,
    paymentTermModalSelector,
    paymentTermsDataSelector,
    paymentTermsLoadingSelector,
    saveLoadingSelector,
    setPaymentTerm,
    setPaymentTermModal,
} from "../../../../ducks/gridCard";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Dimmer, Form, Grid, Input, Loader, Modal, Segment} from "semantic-ui-react";
import {toast} from "react-toastify";
import {isFunction} from "lodash";

const locale = 'ru'

const PaymentTermModal = ({callbackSuccess, name}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const paymentTermModal = useSelector(state => paymentTermModalSelector(state));
    const paymentTermData = useSelector(state => paymentTermsDataSelector(state));
    const paymentTermLoading = useSelector(state => paymentTermsLoadingSelector(state));
    const saveLoading = useSelector(state => saveLoadingSelector(state));

    const [check, setCheck] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const onClose = (callback) => {
        dispatch(setPaymentTermModal({open: false}));
        setCheck(false);
        (callback && isFunction(callback)) ? callback() : (callbackSuccess && callbackSuccess());
    }

    useEffect(() => {
        paymentTermModal.open && dispatch(getPaymentTerms({ids: paymentTermModal.ids, name}))
    }, [paymentTermModal]);

    const onClick = (id) => {
        const checkError = id && !check;
        setCheckError(checkError);
        if (checkError) toast.error(t('paymentTermsRulesConfirmError'));
        else {
            dispatch(setPaymentTerm({
                action: paymentTermModal.actionName,
                ids: paymentTermModal.ids,
                form: {
                    paymentTermId: id
                },
                name,
                callbackSuccess: () => onClose(paymentTermModal.callbackSuccess)
            }));
        }
    }

    const convertToLocalStr = strValue => {
        const parsedValue = parseFloat(strValue)

        return isNaN(parsedValue) ? strValue : parsedValue.toLocaleString(locale)
    }

    return (
        <Modal
            open={paymentTermModal.open}
            size={'small'}
        >
            <Modal.Header className='payment-term-modal-header'>{t('choiceOfPaymentTerm')}</Modal.Header>
            <Modal.Content>
                <Segment vertical>
                    <Dimmer active={paymentTermLoading} inverted>
                        <Loader size='small'/>
                    </Dimmer>
                    <Form className='tabs-card padding-0'>
                        <Grid>
                            <Grid.Row className='padding-bottom-0' columns={3}>
                                <Grid.Column textAlign='center' width="7"><label>{t('totalDeliveryCostWithoutVAT')}</label></Grid.Column>
                                <Grid.Column textAlign='center' width="5"><label>{t('paymentTermDays')}</label></Grid.Column>
                                <Grid.Column width="4"/>
                            </Grid.Row>
                            {
                                (paymentTermData.rows || []).map(row => <Grid.Row className='t-a-center payment-term-modal-cost-row' key={'row_' + row.id} columns={3}>
                                    <Grid.Column width="7">
                                        <Form.Field>
                                            <Input disabled fluid value={convertToLocalStr(row.totalDeliveryCost)}/>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width="5">
                                        <Form.Field>
                                            <Input disabled fluid value={convertToLocalStr(row.paymentTermDays)}/>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width="4">
                                        <Button disabled={saveLoading} loading={saveLoading} fluid primary onClick={() => onClick(row.id)}>
                                            {t('confirm')}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>)
                            }
                            <Grid.Row>
                                <Grid.Column>
                                    <div className='grid-card-header ui checkbox'>
                                        <Form.Field error={checkError}>
                                            <Checkbox
                                                onChange={(e, {checked}) => {
                                                    setCheck(checked);
                                                    setCheckError(false);
                                                }}
                                                checked={check}
                                            />
                                            <div className='ui checkbox payment-terms-rules margin-left-8'>{t('iConfirm')} <a href={paymentTermData.paymentTermsRulesLink} className='docs-download_link'>{t('paymentTermsRules')}</a></div>
                                        </Form.Field>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>{t('CancelButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PaymentTermModal;