import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {downloadApi, progressSelector} from "../ducks/documents";
import {Container, Dimmer, Loader, Segment} from "semantic-ui-react";

function ApiDownloadRoute({}) {
    const dispatch = useDispatch();
    const loading = useSelector(progressSelector);

    useEffect(() => {
        dispatch(downloadApi(window.location.href));
    }, [])

    return <Container>
        <Segment className='download-loader' vertical>
            <Dimmer active={loading} inverted>
                <Loader size="huge"/>
            </Dimmer>
        </Segment>
    </Container>;
}

export default ApiDownloadRoute;
