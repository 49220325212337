import React, {useCallback, useEffect} from 'react';
import FormField from '../customGrid/components/formField';
import {useSelector} from "react-redux";
import {
    isReadOnlyFieldsSelector,
    isVisibleFieldsSelector
} from "../../ducks/dictionaryView";
import {ATTRACTED, LOAN} from "../../constants/ownershipTypes";

const VehicleInformation = ({columns, error, form, onChange, dictionaryName}) => {

    const carrierIsTied = !!form['carrierOwnershipType'];

    const isVisibleFields = useSelector(state => isVisibleFieldsSelector(state, dictionaryName));

    const isReadOnlyFields = useSelector(state => isReadOnlyFieldsSelector(state, dictionaryName));

    const disabledInput = (column) => {
        switch (column) {
            case 'transportCompanyOwnershipType':
                //Тип владения ТК задизейблено, если не выбрана ТК
                return !form["transportCompanyId"];
            case 'carrierName':
            case 'carrierTelephoneNumber':
            case 'carrierLegalAddress':
            case 'carrierOwnershipType':
                return !form["carrierInn"] //задизейблено, если пользователь не выбрал перевозчика в поле ИНН
                     && !carrierIsTied //и если указанный перевозчик не привязан к данному ТС для данной ТК
            // и если пользователь не нажал в поле ИНН ссылку “добавить перевозчика” - без нового компонента не работает
            default:
                return false;
        }
    }

    const isViewInput = (column) => {

        switch (column) {
            case 'carrierInn':
            case 'carrierName':
            case 'carrierTelephoneNumber':
            case 'carrierLegalAddress':
            case 'carrierOwnershipType':
                return [ATTRACTED, LOAN].includes(form["transportCompanyOwnershipType"]); //Тип владения ТК = “Привлеченный” или “Аренда”;
            default:
                return true;
        }
    }

    const handleChangeTransportCompany = (e, {name, value}) => {
        if (!value) onChange(e, {name: 'transportCompanyOwnershipType', value: null});
        onChange(e, {name: name, value: value});
    }

    const requiredFields = ['transportCompanyOwnershipType', 'carrierInn',
        'carrierName', 'carrierTelephoneNumber', 'carrierLegalAddress', 'carrierOwnershipType', 'vehicleEnvironmentalClass', 'fuelType'];

    const onlyLettersAndNumbersFields = ['vehicleNumber', 'trailerNumber', 'passportNumber'];

    const handleChangeInn = useCallback((e, { value }) => {
        if (value) {
            onChange(e, {
                name :'carrierInn',
                value: value['carrierInn'],
            });
            onChange(e, {
                name :'carrierName',
                value: value['carrier'],
            });
            onChange(e, {
                name :'carrierLegalAddress',
                value: value['carrierLegalAddress'],
            });
            onChange(e, {
                name :'address',
                value: value['carrierAddress'] ? value['carrierAddress'] : null,
            });
            onChange(e, {
                name :'cityName',
                value: value['carrierCityName'] ? value['carrierCityName'] : null,
            });
            onChange(e, {
                name :'postalCode',
                value: value['carrierPostalCode'] ? value['carrierPostalCode'] : null,
            });
            onChange(e, {
                name :'carrierTelephoneNumber',
                value: value['carrierTelephoneNumber'] ? value['carrierTelephoneNumber'] : '',
            });
        }

    }, []);

    useEffect(() => {
        onChange(null, {
            name: 'carrierTelephoneNumberForGrid',
            value: form.carrierTelephoneNumber,
        });
    }, [form.carrierTelephoneNumber]);

    const handleChange = (column) => {

        switch (column) {
            case 'transportCompanyId':
                return handleChangeTransportCompany;
            case 'carrierInn':
                return handleChangeInn;
            default:
                return onChange;
        }
    }

    const isReadOnly = (column) => {
        if (dictionaryName === 'transportCompanies') {
            if (column.name === 'reasonPolicyRejection')
                return !form['insurancePolicyValidationState'] || (form['insurancePolicyValidationState'] && form['insurancePolicyValidationState'].value !== 'notConfirmed');
        }
        return false;
    }

    return (
        <div className="ui form dictionary-edit">
            {
                columns.map(column => {
                        if (isVisibleFields[column.name] && !form[isVisibleFields[column.name]])
                            return null;

                        let formField = <FormField
                            {...column}
                            onClickInnInput={column.name === 'carrierInn' && onChange}
                            isReadOnly={(isReadOnlyFields[column.name] && form[isReadOnlyFields[column.name]]) || column.isReadOnly || isReadOnly(column)}
                            noScrollColumn={column}
                            key={column.name}
                            error={error}
                            isDisabled={disabledInput(column.name)}
                            rows={column.name === 'carrierLegalAddress' && 1}
                            form={form}
                            isRequired = {requiredFields.indexOf(column.name) !== -1 || column.isRequired}
                            onChange={handleChange(column.name)}
                            onlyLettersAndNumbers={onlyLettersAndNumbersFields.includes(column.name)}
                        />

                        return isViewInput(column.name) && (column.name === 'carrierLegalAddress' ?
                            <div className='field textarea_carrier'>{formField}</div>
                            : <>
                                {formField}
                                {column.name === 'fuelType' && <div className='line-break'/>}
                            </>);
                    }
                )
            }
        </div>
    )
};

export default VehicleInformation;
