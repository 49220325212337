import React, { useCallback, useEffect } from 'react';
import { Icon, Tab } from 'semantic-ui-react';
import Block from '../../../../components/CardLayout/components/block';
import StopperDocument from './stopperDocument';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupRequest, valuesListSelector } from '../../../../ducks/lookup';

const StopperDocuments = ({ form, settings }) => {
    const dispatch = useDispatch();
    const { stopperDocuments: stopperDocuments = [] } = form;

    return (
        stopperDocuments.length ? stopperDocuments.map((row, i) => (
            <StopperDocument
                index={i}
                form={form}
                stopperDocument={row}
                settings={settings}
            />
        )) : null);
};

export default StopperDocuments;
