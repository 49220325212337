import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import CodeEditor from '@uiw/react-textarea-code-editor';

const Code = ({
                     value,
                     name,
                     onChange,
                     isDisabled,
                     noLabel,
                     text,
                     isRequired,
                     error,
                     width,
                     fieldClassName = ''
                 }) => {
    const { t } = useTranslation();

    return (
        <Form.Field style={{width: width || 'auto'}} className={fieldClassName}>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <CodeEditor
                className='code-area'
                value={value || ''}
                language="tsx"
                onChange={(evn) => onChange(evn, {name, value: evn.target.value})}
                padding={15}
                style={{
                    fontSize: 14,
                    backgroundColor: "white",
                    color: '#464646'
                }}
                disabled={isDisabled}
            />

            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
        </Form.Field>
    );
};
export default Code;
