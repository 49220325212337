import React, {useEffect, useCallback, useState} from 'react';
import CardLayout from "../../../components/CardLayout";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    anomaliesPhotosSelector,
    anomalyPhotosLoadingSelector,
    downloadAnomalyPhoto,
    downloadAnomalyPhotosArchive,
    getAnomaliesPhotos,
    progressSelector
} from "../../../ducks/gridCard";
import {Button, Popup} from "semantic-ui-react";

const AnomaliesPhotos = ({isStopper, ...props}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {match} = props;
    const {params = {}} = match;
    const {id} = params;

    const [downloadId, setDownloadId] = useState(null);

    const loading = useSelector(state => anomalyPhotosLoadingSelector(state));
    const loadingDownload = useSelector(state => progressSelector(state));
    const photos = useSelector(state => anomaliesPhotosSelector(state));

    useEffect(() => {
        id && dispatch(getAnomaliesPhotos({
            id,
            isStopper
        }))
    }, [id, isStopper]);

    const getPhoto = (link) => {
        return `${window.location.origin}/api/anomalyDocuments?id=${link}`;
    }

    const downloadPhoto = (link, id) => {
        setDownloadId(link);
        dispatch(downloadAnomalyPhoto({link, isStopper, id}));
    }

    const downloadArchive = () => {
        setDownloadId(null);
        dispatch(downloadAnomalyPhotosArchive({id, isStopper}));
    }

    const anomalyPhotos = (anomaly) => {
        return <div className={'photo-anomaly__block'} key={anomaly.id}>
            <h5>{anomaly.name}</h5>
            {
                [...anomaly.externalLinks, ...anomaly.internalLinks].map(link => (
                    <span className='photo-anomaly' key={link.fileLink}>
                        <a href={link.fileId ? getPhoto(link.fileLink) : link.fileLink} target="new">
                           <img
                               className='photo-anomaly__img'
                               src={link.fileId ? getPhoto(link.fileLink) : link.fileLink} alt='img'
                               width={190} height={190}
                               style={{objectFit: 'cover'}}/>
                        </a>
                        <Button
                            color='black'
                            className='photo-anomaly__delete-btn'
                            icon='download'
                            loading={(downloadId === link.fileLink) && loadingDownload}
                            onClick={() => !((downloadId === link.fileLink) && loadingDownload) && downloadPhoto(link.fileLink, link.fileId)}/>
                    </span>
                ))
            }
        </div>
    }

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                <Popup
                    content={t('downloadAllPhotosArchive')}
                    position="bottom right"
                    trigger={
                        <Button
                            disabled={(!downloadId && loadingDownload) || !photos.length}
                            loading={!downloadId && loadingDownload}
                            icon="download"
                            onClick={downloadArchive}
                            content={t('downloadAllPhotosArchive')}
                        />
                    }
                />
            </div>
        );
    }, [loadingDownload, downloadId, photos]);

    return (
        <>
            <CardLayout
                title={t('photos')}
                actionsFooter={() => null}
                actionsHeader={getActionsHeader}
                loading={loading}
            >
                <div className={'user-form'}>
                    {
                        photos.length ? photos.map(a => isStopper
                            ? <div className='photo-anomaly__point' key={a.id}>
                                <h3>{a.name}</h3>
                                {
                                    a.anomalies.map(st => anomalyPhotos(st))
                                }
                            </div>
                            : anomalyPhotos(a)) : <div>{t('noPhotos')}</div>
                    }
                </div>
            </CardLayout>
        </>
    );
};

export default AnomaliesPhotos;