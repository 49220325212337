import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    errorSelector,
    phoneModalSelector,
    saveLoadingSelector,
    saveUserPhone,
    setPhoneModal
} from "../../../../ducks/gridCard";
import {PHONE_NUMBER_TYPE} from "../../../../constants/columnTypes";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {useTranslation} from "react-i18next";

const PhoneNumberModal = ({callbackSuccess}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const phoneModal = useSelector(state => phoneModalSelector(state));
    const saveUserPhoneLoading = useSelector(state => saveLoadingSelector(state));
    const error = useSelector(state => errorSelector(state));

    const savePhoneNumber = (data) => {
        dispatch(saveUserPhone({...data, ids: phoneModal.ids, action: phoneModal.actionName || null}));
    };

    return (
        <RequiredFieldsModal
            open={phoneModal.open}
            id={null}
            onClose={() => {
                dispatch(setPhoneModal({open: false}));
                callbackSuccess && callbackSuccess();
            }}
            error={error}
            text={phoneModal.oldPhone ? `${t('phoneNumberVerificationText')} ${phoneModal.oldPhone}?` : t('noPhoneNumberText')}
            title={t('phoneNumberVerification')}
            fields={[
                {
                    isRequired: true,
                    name: "phoneNumber",
                    text: "actualPhoneNumber",
                    type: PHONE_NUMBER_TYPE
                }
            ]}
            saveRequest={savePhoneNumber}
            saveBtnName={t('confirm')}
            loading={saveUserPhoneLoading}
            oldForm={{
                phoneNumber: phoneModal.oldPhone
            }}
        />
    );
};

export default PhoneNumberModal;