import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {docsAndInstructionsSelector, getDocsAndInstructions, progressSelector} from "../../ducks/personalCabinet";
import FileBlock from "./fileBlock";
import {downloadDocumentRequest, downloadFileProgressSelector} from "../../ducks/documents";
import {Dimmer, Form, Loader, Segment} from "semantic-ui-react";

const DocumentsAndInstructions = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [id, setId] = useState(null);

    const docsAndInstructions = useSelector(docsAndInstructionsSelector);
    const downloadFileProgress = useSelector(downloadFileProgressSelector);
    const progress = useSelector(progressSelector);

    useEffect(() => {
        dispatch(getDocsAndInstructions());
    }, []);

    const handleDownload = id => {
        setId(id);
        dispatch(downloadDocumentRequest({id}))
    }

    return (
        <div className="container personal-cabinet__page">
            <h2>{t('documentsAndInstructions')}</h2>
            <Form>
                <Segment vertical className='files-view'>
                    <Dimmer inverted active={progress}>
                        <Loader/>
                    </Dimmer>
                    {docsAndInstructions.map(doc => (<FileBlock
                        loading={id === doc.fileId && downloadFileProgress}
                        fileName={doc.name}
                        fileId={doc.fileId}
                        fileDescription={doc.description}
                        fileDownload={handleDownload}
                    />))}
                </Segment>
            </Form>
        </div>
    );
};

export default DocumentsAndInstructions;