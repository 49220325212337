import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';

const TextCropping = ({
    children, width: columnWidth, style, hoverable = false,
}) => {
    const valueRef = useRef(null);
    const [width, setWidth] = useState({
        scrollWidth: 0,
        offsetWidth: 0,
    });

    useEffect(
        () => {
            setWidth({
                scrollWidth: valueRef.current && valueRef.current.scrollWidth,
                offsetWidth: valueRef.current && valueRef.current.offsetWidth,
            });
        },
        [valueRef.current, columnWidth, children],
    );

    return (
        <Popup
            content={(
                <div className="popup-text" style={{ whiteSpace: 'pre-line' }}>
                    {children}
                </div>
            )}
            context={valueRef}
            disabled={width.scrollWidth <= width.offsetWidth}
            position="top center"
            basic
            hoverable={hoverable}
            trigger={(
                <div className="column-overflow" ref={valueRef} style={style}>
                    {children}
                </div>
            )}
        />
    );
};

export default TextCropping;
