import React from 'react';

import {
    ACTIVE_TYPE,
    BOOLEAN_TYPE,
    CUSTOM_SELECT_TYPE,
    CUSTOM_STATE_TYPE,
    ENUM_TYPE,
    LABELS_TYPE,
    LINK_TYPE,
    LOCAL_DATE_TIME,
    NUMBER_TYPE,
    SELECT_TYPE,
    MULTISELECT_TYPE,
    STATE_TYPE,
    MONEY_TYPE,
    LINKS_TYPE,
    COMMENTS_TYPE,
    MULTI_ENUM_TYPE,
    TIME_ZONE_TYPE,
    SELECT_WITH_COMMENT_TYPE,
    DATES_TYPE,
    DATES_OR_PERIOD_TYPE
} from '../../constants/columnTypes';
import { numbersFormat } from '../../utils/numbersFormat';
import { Label } from 'semantic-ui-react';
import StateValue from './StateValue';
import SelectValue from './SelectValue';
import TextCropping from './TextCropping';
import ToggleCheckbox from './ToogleCheckbox';
import {commentsToText} from "../../utils/comments";
import {dateToUTC} from "../../utils/dateTimeFormater";

const CellValue = ({
    name,
    type,
    value = '',
    valueText,
    id,
    toggleIsActive,
    source,
    indexRow,
    indexColumn,
    modalCard,
    showRawValue,
    width,
    t,
    isDisabled,
    cardLink,
    gridName,
    rowId,
    goToCard,
    decimals,
    style = null,
    newWindow = false,
    isAvailable
}) => {
    if (type === SELECT_TYPE || type === CUSTOM_SELECT_TYPE || type === SELECT_WITH_COMMENT_TYPE) {
        return (
            <SelectValue
                width={width}
                value={value}
                valueText={valueText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }

    if (type === MULTISELECT_TYPE || type === MULTI_ENUM_TYPE) {
        return <TextCropping width={width} indexColumn={indexColumn} style={style}>
            {value ? value.reduce((str, item) => `${str}${str.length ? ', ' : ''}${item.name}`, '') :''}
        </TextCropping>
    }

    if (type === STATE_TYPE || type === CUSTOM_STATE_TYPE) {
        return (
            <StateValue
                width={width}
                value={value}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
            />
        );
    }

    if (type === LABELS_TYPE) {
        return (
            <>
                {!value
                    ? t('All')
                    : value.map((n, i) => (
                          <Label key={n.name} className="label-margin">
                              {t(n.name)}
                          </Label>
                      ))}
            </>
        );
    }

    if (type === ENUM_TYPE || type === TIME_ZONE_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {value ? valueText : ''}
            </TextCropping>
        );
    }

    if (type === ACTIVE_TYPE) {
        return (
            <ToggleCheckbox
                id={id}
                value={value}
                disabled={isDisabled}
                toggleIsActive={toggleIsActive}
                name={name}
            />
        );
    }

    if (type === BOOLEAN_TYPE) {
        return <>{value === true ? t('Yes') : value === false ? t('No') : ''}</>;
    }

    if (value === undefined || value === null) return '';

    if (type === NUMBER_TYPE || type === MONEY_TYPE) {
        if (decimals !== undefined && decimals !== null) {
            return <>{new Intl.NumberFormat().format(numbersFormat(parseFloat(value).toFixed(decimals)))}</>; // new Intl.NumberFormat().format() чтоб разделитель дробной части брался из настроек системы)
        }
        return <>{new Intl.NumberFormat().format(numbersFormat(parseFloat(value)))}</>;
    }

    if (type === LINK_TYPE && isAvailable) {
        const handleGoToCard = () => {
            goToCard(true, rowId, source, newWindow, value);
        };

        return (
            <>
                {goToCard ? (
                    <div className="link-cell" onClick={handleGoToCard}>
                        <TextCropping width={width} indexColumn={indexColumn}>
                            {value}
                        </TextCropping>
                    </div>
                ) : (
                    <TextCropping width={width} indexColumn={indexColumn}>
                        {value}
                    </TextCropping>
                )}
            </>
        );
    }

    if (type === LOCAL_DATE_TIME) {
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {dateToUTC(value, 'DD.MM.YYYY HH:mm')}
            </TextCropping>
        );
    }

    if (type === LINKS_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn} hoverable style={{cursor: 'pointer'}}>
                {(value && value.length) ? value.map(link => <a key={link} href={link} target="_blank">{link}{'\n\r'}</a>) : ''}
            </TextCropping>
        );
    }

    if (type === COMMENTS_TYPE) {
        return (
            <TextCropping hoverable width={width} indexColumn={indexColumn} style={style}>
                {commentsToText(value)}
            </TextCropping>
        );
    }

    if (type === DATES_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn} style={style}>
                {(value || []).join(', ')}
            </TextCropping>
        );
    }

    if (type === DATES_OR_PERIOD_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn} style={style}>
                {(value || []).join(', ')}
            </TextCropping>
        );
    }

    return (
        <TextCropping width={width} indexColumn={indexColumn} style={style}>
            {value}
        </TextCropping>
    );
};

export default React.memo(CellValue);
