import React from 'react';
import LinksBlock from "./linksBlock";
import {PERSONAL_CABINET_AVAILABLE_PARK} from "../../constants/personalCabinetPages";

const AvailablePark = () => {
    return (
        <LinksBlock
            type={0}
            name={PERSONAL_CABINET_AVAILABLE_PARK}
            color={'violet'}
            icon={'truck'}
        />
    );
};

export default AvailablePark;