import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {driverLicenseNumberSelector, saveLoadingSelector, setDriverLicense} from "../../../../ducks/gridCard";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {DATE_TYPE, TEXT_TYPE} from "../../../../constants/columnTypes";

const DriverLicenseModal = ({open = false, error, onClose, driverId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const saveLoading = useSelector(state => saveLoadingSelector(state));
    const driverLicenseNumber = useSelector(state => driverLicenseNumberSelector(state));

    const saveLicense = (data) => {
        dispatch(setDriverLicense({...data, callbackSuccess: onClose}));
    }

    return (
        <RequiredFieldsModal
            open={open}
            id={driverId}
            onClose={onClose}
            error={error}
            text={t('verifyDriverLicenseModalText')}
            title={t('driverLicenseModalTitle')}
            fields={[
                {

                    isRequired: true,
                    name: "driverLicenseNumber",
                    text: "driverLicenseNumberTitle",
                    type: TEXT_TYPE
                },
                {

                    isRequired: true,
                    name: "driverLicenseValidUntil",
                    text: "driverLicenseValidUntil",
                    type: DATE_TYPE
                },
            ]}
            saveRequest={saveLicense}
            loading={saveLoading}
            oldForm={{
                driverLicenseNumber
            }}
        />
    );
}

export default DriverLicenseModal;
