import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Information from './shippingTabs/information';
import Stoppers from './shippingTabs/stoppers';
import History from './shared/history';
import SecurityCases from './shippingTabs/securityCases';
import {useDispatch, useSelector} from 'react-redux';
import {userNameSelector, userPermissionsSelector} from '../../../ducks/profile';
import CardLayout from '../../../components/CardLayout';
import Costs from './shippingTabs/costs';
import AuctionPriceOffers from "./shippingTabs/auctionPriceOffers";
import AuctionParameters from "./shippingTabs/auctionParameters";
import AuctionPriceOffer from "./shippingTabs/auctionPriceOffer";
import {getSecurityCasesRequest} from "../../../ducks/securityCases";
import DeliveryDocuments from "./shippingTabs/deliveryDocuments";
import AnomaliesShipping from "./shippingTabs/anomaliesShipping";
import {SETTINGS_TYPE_HIDE} from "../../../constants/formTypes";
import {anomaliesLoadingSelector} from "../../../ducks/gridCard";
import Fines from "./shippingTabs/fines";

const ShippingCard = ({
    form,
    onChangeForm,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
    updateHistory,
    saveForm,
    scrollTo = null
}) => {
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    const canScroll = !useSelector(state => anomaliesLoadingSelector(state)) && !loading;
    let [routeActiveIndex, setRouteActiveIndex] = useState(0);

    const handleTabChange = useCallback((e, { activeIndex }) => {
        setRouteActiveIndex(activeIndex);
    }, []);

    const dispatch = useDispatch();

    const getSecurityCases = (id) => {
        id && dispatch(getSecurityCasesRequest(id));
    };

    const loadData = () => {
        load();
        getSecurityCases(id);
    };

    useEffect(() => {
        getSecurityCases(id);
    }, [id]);

    const getPanes = () => {
        const obj = [
            {
                menuItem: 'information',
                render: () => (
                    <Information
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                ),
            },
            {
                menuItem: 'securityCases',
                render: () => <SecurityCases cardId={id} status={form.status} />,
            },
            {
                menuItem: 'shippingAnomalies',
                isOpen: false,
                render: () => (
                    <AnomaliesShipping
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        updateHistory={updateHistory}
                        disabled={settings && settings['isAnomaliesAvailable'] === SETTINGS_TYPE_HIDE}
                    />
                ),
            },
            {
                menuItem: 'stoppersFormCaption',
                render: () => (
                    <Stoppers
                        form={form}
                        routeActiveIndex={routeActiveIndex}
                        settings={settings}
                        tabChange={handleTabChange}
                        onChange={onChangeForm}
                        updateHistory={updateHistory}
                    />
                ),
            },
            {
                menuItem: 'deliveryDocuments',
                render: () => (
                    <DeliveryDocuments
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                ),
            },
            {
                menuItem: 'costs',
                render: () => (
                    <Costs
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            },
            {
                menuItem: 'fines',
                render: () => (
                    <Fines
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            }
        ];

        if (form.auctionPriceOffers && form.auctionPriceOffers.isVisible) {
            obj.unshift({
                menuItem: 'auctionPriceOffers',
                render: () => (
                    <AuctionPriceOffers
                        load={loadData}
                        items={form.auctionPriceOffers.items}
                        shippingVersion={form.rowVersion}
                        cardId={id}
                        isSelectButtonsVisible={form.auctionPriceOffers.isSelectButtonsVisible}
                    />
                )
            })
        }

        if (form.isAuctionVisible) {
            obj.unshift({
                menuItem: 'auctionParameters',
                render: () => (
                    <AuctionParameters
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                )
            })
        }

        if (form.isAuctionPriceOfferVisible) {
            obj.unshift({
                menuItem: 'auctionPriceOffer',
                render: () => (
                    <AuctionPriceOffer
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        saveForm={saveForm}
                        load={loadData}
                    />
                ),
            })
        }

        if (userPermissions.includes(12)) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            });
        }

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
            scrollTo={canScroll && scrollTo}
        />
    );
};

export default ShippingCard;
