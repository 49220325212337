import React, {useState, useEffect, useRef, useCallback} from 'react';
import './style.scss';
import Banner from "./Banner";
import {useDispatch, useSelector} from "react-redux";
import {bannersSelector, clickBannerRequest, closeBannerRequest, getBannersRequest} from "../../ducks/banners";

const BannersBlock = ({setHeight}) => {
    const time = 120000; //2 min
    const dispatch = useDispatch();

    const [bannerIndex, setBannerIndex] = useState(0);
    const [timer, setTimer] = useState(null);

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight);
    }));

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container);
        else if (ro.current) {
            ro.current.disconnect();
            setHeight(0);
        }
    }, [ro.current]);

    const banners = useSelector(state => bannersSelector(state));

    const setNextBanner = () => setBannerIndex(bannerInx => bannerInx < banners.length - 1 ? bannerInx + 1 : 0);

    const dropTimer = () => {
        if (timer) clearTimeout(timer);
        setTimer(setTimeout(setNextBanner, time));
    };

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        bannerIndex >= banners.length && setNextBanner();
        dropTimer();
    }, [banners, bannerIndex]);

    const getBanners = () => {
        dispatch(getBannersRequest());
    }

    const onClose = (id) => {
        dispatch(closeBannerRequest({id, callback: getBanners}));
    }

    const onClick = (id) => {
        dispatch(clickBannerRequest({id, callback: getBanners}));
    }

    let banner = banners[bannerIndex];

    return (
        <>
            {banner && <div ref={resize} className='banner-block'>
                <Banner
                    id={banner.id}
                    title={banner.title}
                    btnTitle={banner.buttonTitle}
                    text={banner.text}
                    url={banner.redirectUrl}
                    onClose={onClose}
                    onClick={onClick}
                />
            </div>}
        </>
    );
};

export default BannersBlock;
