import {ACTIVE_TYPE, NUMBER_TYPE} from './columnTypes';

export const maxWidthColumnSettings = {
    maxWidth: '400px',
    wordWrap: 'break-word',
    whiteSpace: 'normal'
}

export const bannersColumns = [
    {
        name: 'createAtLocal',
        displayNameKey: 'createAt',
        notFilter: true,
        noSort: true
    },
    {
        name: 'title',
        displayNameKey: 'titleBanner',
        notFilter: true,
        noSort: true,
        style: maxWidthColumnSettings
    },
    {
        name: 'period',
        displayNameKey: 'period',
        notFilter: true,
        noSort: true
    },
    {
        name: 'text',
        displayNameKey: 'text',
        notFilter: true,
        noSort: true,
        style: {...maxWidthColumnSettings, minWidth: '400px'}
    },
    {
        name: 'buttonTitle',
        displayNameKey: 'buttonTitle',
        notFilter: true,
        noSort: true,
        style: maxWidthColumnSettings
    },
    {
        name: 'redirectUrl',
        displayNameKey: 'redirectUrl',
        notFilter: true,
        noSort: true,
        style: maxWidthColumnSettings
    },
    {
        name: 'clicksCount',
        displayNameKey: 'clicksCount',
        type: NUMBER_TYPE,
        notFilter: true,
        noSort: true
    },
    {
        name: 'isActive',
        displayNameKey: 'isActive',
        type: ACTIVE_TYPE,
        notFilter: true,
        noSort: true
    }
];
