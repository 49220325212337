export const backlightsColorsStyle = (row, colors) => {
    if (row.backlights && row.backlights.length) {
        let color = null;
        Object.keys(colors).forEach(c => {
            if (row.backlights.includes(c)) {
                color = colors[c]
            }
        })
        if (color) return {
            backgroundColor: color
        };
    }
    return null;
}