import React from 'react';
import {Form, Grid} from "semantic-ui-react";
import FormField from "../formField";
import {useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";

const Fines = ({ form = {}, onChange, settings, error }) => {
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID));

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={16}>
                    <Grid.Column width={10}>
                        <FormField
                            name="penaltyType"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <FormField
                            name="penaltySize"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Fines;