import qs from "qs";

export const setUrlSearchParam = (location, name, value, clearOthers = false, clearArray) => {
    let params = clearOthers ? {} : Object.fromEntries(new URLSearchParams(location.search.replace('?', '')));
    value !== null ? params[name] = value : delete params[name];
    if (clearArray) clearArray.forEach(f => delete params[f]);
    return {
        pathname: location.pathname,
        search: `?${new URLSearchParams(params).toString()}`,
    }
};

export const filtersFromSearch = (location) => {
    const {mode, ...filters} = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
    return filters;
}

export const goBack = (history, location, id) => {
    history.length <= 2
        ? history.replace(location.pathname.replace(id, ''))
        : history.goBack()
}
