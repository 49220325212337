export default [
    {
        text: 'Polski',
        value: 'ru',
        flag: 'pl',
    },
    {
        text: 'English',
        value: 'en',
        flag: 'us',
    },
];
