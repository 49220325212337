import {all, put, takeEvery, call, delay, select} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {postman} from '../utils/postman';

//*  TYPES  *//

const GET_NOTIFICATION_SETTINGS_REQUEST = 'GET_NOTIFICATION_SETTINGS_REQUEST';
const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
const GET_NOTIFICATION_SETTINGS_ERROR = 'GET_NOTIFICATION_SETTINGS_ERROR';

const GET_PARAMS_SETTINGS_REQUEST = 'GET_PARAMS_SETTINGS_REQUEST';
const GET_PARAMS_SETTINGS_SUCCESS = 'GET_PARAMS_SETTINGS_SUCCESS';
const GET_PARAMS_SETTINGS_ERROR = 'GET_PARAMS_SETTINGS_ERROR';

const SAVE_NOTIFICATION_SETTINGS_REQUEST = 'SAVE_NOTIFICATION_SETTINGS_REQUEST';
const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'SAVE_NOTIFICATION_SETTINGS_SUCCESS';
const SAVE_NOTIFICATION_SETTINGS_ERROR = 'SAVE_NOTIFICATION_SETTINGS_ERROR';

const GET_NOTIFICATION_TYPES_REQUEST = 'GET_NOTIFICATION_TYPES_REQUEST';
const GET_NOTIFICATION_TYPES_SUCCESS = 'GET_NOTIFICATION_TYPES_SUCCESS';
const GET_NOTIFICATION_TYPES_ERROR = 'GET_NOTIFICATION_TYPES_ERROR';

const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
const SAVE_SETTINGS_ERROR = 'SAVE_SETTINGS_ERROR';

const VERSION_REQUEST = 'VERSION_REQUEST';
const VERSION_SUCCESS = 'VERSION_SUCCESS';
const VERSION_ERROR = 'VERSION_ERROR';

//*  INITIAL STATE  *//

const initial = {
    notificationSettings: {},
    notificationTypes: [],
    parameters: [],
    progress: false,
    version: null
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_NOTIFICATION_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                notificationSettings: payload,
                progress: false,
            };
        case GET_NOTIFICATION_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SAVE_NOTIFICATION_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case SAVE_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                progress: false,
                errorSaveNotifications: false
            };
        case SAVE_NOTIFICATION_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
                errorSaveNotifications: true
            };
        case GET_NOTIFICATION_TYPES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_NOTIFICATION_TYPES_SUCCESS:
            return {
                ...state,
                notificationTypes: payload,
                progress: false,
            };
        case GET_NOTIFICATION_TYPES_ERROR:
            return {
                ...state,
                progress: false,
            };
        case GET_PARAMS_SETTINGS_REQUEST:
            return {
                ...state,
                parameters: [],
                progress: true,
            };
        case GET_PARAMS_SETTINGS_SUCCESS:
            return {
                ...state,
                parameters: payload,
                progress: false,
            };
        case GET_PARAMS_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SAVE_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true
            };
        case SAVE_SETTINGS_SUCCESS:
            return {
                ...state,
                progress: false,
                errorSaveSettings: false
            };
        case SAVE_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
                errorSaveSettings: true
            };
        case VERSION_SUCCESS:
            return {
                ...state,
                version: (payload || {}).appVersion
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const saveSettingsRequest = payload => {
    return {
        type: SAVE_SETTINGS_REQUEST,
        payload,
    };
};

export const getNotificationsSettingsRequest = payload => {
    return {
        type: GET_NOTIFICATION_SETTINGS_REQUEST,
        payload,
    };
};

export const getParamsSettingsRequest = payload => {
    return {
        type: GET_PARAMS_SETTINGS_REQUEST,
        payload,
    };
};

export const saveNotificationsSettingsRequest = payload => {
    return {
        type: SAVE_NOTIFICATION_SETTINGS_REQUEST,
        payload,
    };
};

export const getNotificationsTypesRequest = payload => {
    return {
        type: GET_NOTIFICATION_TYPES_REQUEST,
        payload,
    };
};

export const getVersion = () => {
    return {
        type: VERSION_REQUEST,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.params;
export const notificationSettingsSelector = createSelector(stateSelector, state => state.notificationSettings);
export const nameCompanySelector = createSelector(stateSelector, state => state.notificationSettings.campaignName || '');
export const notificationTypesSelector = createSelector(stateSelector, state => state.notificationTypes);
export const paramsSelector = createSelector(stateSelector, state => state.parameters);
export const loadingSelector = createSelector(stateSelector, state => state.progress);
export const noErrorsSelector = createSelector(stateSelector, state => state.errorSaveSettings === false && state.errorSaveNotifications === false);

//*  SAGA  *//

function* getNotificationsSettingsSaga({payload}) {
    try {
        const result = yield postman.post(`/shippingnotification/getNotifications`, null, {
            params: {
                transportCompanyId: payload
            }
        });

        yield put({
            type: GET_NOTIFICATION_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_NOTIFICATION_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* getParamsSettingsSaga({payload}) {
    try {
        const result = yield postman.post(`/shippingnotification/getParameters`, null, {
            params: {
                transportCompanyId: payload
            }
        });

        yield put({
            type: GET_PARAMS_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_PARAMS_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* saveNotificationsSettingsSaga({payload}) {
    try {
        const result = yield postman.post(`/shippingnotification/Save`, payload);

        yield put({
            type: SAVE_NOTIFICATION_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: SAVE_NOTIFICATION_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* getNotificationsTypesSaga() {
    try {
        const result = yield postman.post(`/noticeType/forSelect`);

        yield put({
            type: GET_NOTIFICATION_TYPES_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_NOTIFICATION_TYPES_ERROR,
            payload: e,
        });
    }
}

function* saveSettingsSaga({payload}) {
    try {
        const result = yield postman.post(`/shippingnotification/saveParameter`, payload);

        yield put({
            type: SAVE_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: SAVE_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* fetchAPI() {
    try {
        const data = yield postman.get('/systemVersion');
        const value = yield select(state => state.params.version);

        if (value && (data || {}).appVersion && (value !== data.appVersion)) {
            window.location.reload();
        }

        yield put({
            type: VERSION_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: VERSION_ERROR
        });
    }
}

function* fetchDataTimer() {
    yield call(fetchAPI);
    yield delay(600000); //10 min
    yield* fetchDataTimer();
}

export function* saga() {
    yield all([
        takeEvery(GET_NOTIFICATION_SETTINGS_REQUEST, getNotificationsSettingsSaga),
        takeEvery(GET_NOTIFICATION_TYPES_REQUEST, getNotificationsTypesSaga),
        takeEvery(SAVE_NOTIFICATION_SETTINGS_REQUEST, saveNotificationsSettingsSaga),
        takeEvery(GET_PARAMS_SETTINGS_REQUEST, getParamsSettingsSaga),
        takeEvery(SAVE_SETTINGS_REQUEST, saveSettingsSaga),
        takeEvery(VERSION_REQUEST, fetchDataTimer),
    ]);
}
