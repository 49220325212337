import React, {useEffect} from 'react';
import {linkSelector, shurlLink} from "../ducks/gridCard";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function ShurlRoute({...props}) {
    const {match, history} = props;
    const {params = {}} = match;

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const link = useSelector(state => linkSelector(state));

    useEffect(() => {
        dispatch(shurlLink(params.id));
    }, [params]);

    useEffect(() => {
        params.id && link && link !== '/' && window.open(link, "_self");
        if (link === '/') {
            history.push(link);
            toast.error(t('invalidShortUrl'));
        }
    }, [link]);

    return null;
}

export default ShurlRoute;
