import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import SelectionField from './selectionField';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../ducks/modal';

const GridParams = ({ form, onChangeForm: setForm, columns = [], id, nameTC: name }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const deleteRow = (id) => {
        let data = form.filter((row, index) => row.id !== id);
        setForm(data);
        return hideModal();
    };

    const onDeleteRow = (id) => {
        dispatch(
            showModal({
                buttons: [
                    {
                        type: 0,
                        text: t('Cancel'),
                        action: hideModal,
                    },
                    {
                        type: 1,
                        text: t('Yes'),
                        action: () => deleteRow(id),
                    },
                ],
                message: t('confirmDeletion'),
                text: t('deleteLine'),
            }),
        );
    };

    const copyRow = (id) => {
        let data = {
            ...form.find((row, index) => row.id === id),
            id: 'new_row' + form.length,
            isNew: true,
        };
        setForm([...form, data]);
    };

    const newRow = () => {
        setForm([
            ...form,
            { id: 'new_row' + form.length, isNew: true, transportCompanyId: id, names: name },
        ]);
    };

    const onChangeValue = (id, { name, value, isExcept }) => {
        let formChange = [...form];
        let i = formChange.findIndex((row) => row.id === id);
        if (formChange[i]) {
            formChange[i][name] = {
                value,
                isExcept,
            };
        }
        setForm(formChange);
    };

    const colorRow = (row, index) => {
        return row.isNew && index === form.length - 1
            ? '#c9ffca'
            : !(row.userId && row.userId !== '') && name
            ? '#f1f1f1'
            : 'white';
    };

    const rowsGrid = useCallback(() => {
        return (
            form &&
            form.map((row, index) => (
                <Table.Row key={'row' + row.id} style={{ backgroundColor: colorRow(row, index) }}>
                    {columns.map((col, index) => (
                        <Table.Cell
                            verticalAlign={col.name !== 'names' && 'top'}
                            className={
                                col.name === 'names'
                                    ? 'params-notifications__col_min'
                                    : 'params-notifications__col'
                            }
                            key={col.name + row.id}
                        >
                            <SelectionField
                                valueIsName={col.source !== 'zones'}
                                rowWithoutValues = {!columns.some(col=>row[col.name] && row[col.name].value && row[col.name].value.length)}
                                readOnly={!!(id && row.userId && row.userId !== '')}
                                key={col.name + row.id}
                                column={col}
                                row={row[col.name]}
                                onChange={({ name, value, isExcept }) => {
                                    onChangeValue(row.id, { name, value, isExcept });
                                }}
                            />
                        </Table.Cell>
                    ))}
                    <Table.Cell>
                        <div className="params-notifications__actions">
                            <Popup
                                content={t('add')}
                                position="bottom right"
                                trigger={
                                    <div onClick={() => newRow()}>
                                        <Icon name="plus" />
                                    </div>
                                }
                            />
                            {((!(row.userId && row.userId !== '') && id) || !id) && (
                                <Popup
                                    content={t('copy')}
                                    position="bottom right"
                                    trigger={
                                        <div onClick={() => copyRow(row.id)}>
                                            <Icon name="copy" />
                                        </div>
                                    }
                                />
                            )}
                            {form.length !== 1 && (
                                <Popup
                                    content={t('delete')}
                                    position="bottom right"
                                    trigger={
                                        <div onClick={() => onDeleteRow(row.id)}>
                                            <Icon name="delete" />
                                        </div>
                                    }
                                />
                            )}
                        </div>
                    </Table.Cell>
                </Table.Row>
            ))
        );
    }, [form, name]);

    return (
        <Table celled className="params-notifications__table">
            <Table.Header>
                <Table.Row>
                    {columns.map((column) => (
                        <Table.HeaderCell key={column.name}>
                            {t(column.displayNameKey)}
                        </Table.HeaderCell>
                    ))}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>

            <Table.Body>{rowsGrid()}</Table.Body>
        </Table>
    );
};

export default GridParams;
