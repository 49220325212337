import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    && {
        height: 95%;
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }
`;

export const StyledModalHeader = styled(Modal.Header)`
    text-align: center;
    font-size: 16px;
    padding: 25px 0;
`;

export const StyledModalContent = styled(Modal.Content)`
    flex-grow: 1;
`;
