import { createSelector } from 'reselect';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';
import './style.scss';
import { getLookupRequest, listFromMultiSelect, } from '../../ducks/lookup';
import { userPermissionsSelector, } from '../../ducks/profile';

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    name,
    text,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    children,
    sourceParams,
    allowAdditions,
    withAll
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams,
                withAll,
            }),
        );
    }, []);

    const userPermissions = useSelector(state => userPermissionsSelector(state));

    const valuesListFromMultiSelect = useSelector(state =>
        listFromMultiSelect(state, source, isTranslate, t),
    );

    const valuesList = allowAdditions ? [...valuesListFromMultiSelect, ...(value ? value.filter(v => !valuesListFromMultiSelect.map(val => val.value).includes(v.value)): [])].map(v => {
        return {...v, text: v.text || v.name}
    }) : valuesListFromMultiSelect;

    const handleChange = (e, { value, addValue }) => {
        const values = addValue ? [...valuesList, addValue] : valuesList;
        let valueIsBlocking = values.find(v=>value.includes(v.value) && v.isBlocking);
        let valueNew = !valueIsBlocking ? value : [valueIsBlocking.value];
        onChange(e, { value: (valueNew && valueNew.length) ? values.filter(item => valueNew.includes(item.value)) : null, name });
    };

    const txt = () => {
        if (value && value.find(v=>v.isBlocking)) {
            let val = valuesList.find(v=>v.value === value.find(v=>v.isBlocking).value);
            return val ? val.text : textValue;
        } else return textValue;
    }

    const onAddItem = (e, {value: newValue}) => {
        handleChange(null, {
            value: [...value.map(v=>v.value), newValue],
            addValue: {
                text: newValue,
                name: newValue,
                value: newValue
            }})
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    allowAdditions={allowAdditions}
                    placeholder={placeholder}
                    fluid
                    clearable={clearable}
                    selection
                    loading={loading}
                    search
                    text={txt()}
                    error={error}
                    multiple
                    value={value && !value.find(v=>v.isBlocking) ? value.map(item => item.value) : []}
                    options={valuesList}
                    onChange={handleChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    disabled={isDisabled}
                    onAddItem={onAddItem}
                />
                {children && children}
            </div>
            {error && typeof error === 'string' && <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />}
        </Form.Field>  
    );
};
export default React.memo(MultiSelect);