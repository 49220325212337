import React, { useRef, useEffect, useState } from 'react';
import FacetField from '../../FilterComponents';

const HeaderCellComponent = ({row, index, setFilter, setSort, filters = {}, sort = {}, gridName}) => {
    const cellRef = useRef(null);
    let [position, setPosition] = useState(null);

    useEffect(() => {
        setPosition(cellRef.current.offsetLeft);
    }, []);

    return (
        <th
            className={`${row.isFixedPosition ? 'no-scroll table-header-cell' : 'table-header-cell'} ${index === 0 && 'column-facet__first'}`}
            ref={cellRef}
            style={
                row.isFixedPosition
                    ? {
                          left: position,
                          maxWidth: '150px',
                          minWidth: '150px',
                      }
                    : null
            }
        >
            <FacetField
                key={'facet' + row.name}
                notWrapHeader
                index={index}
                name={row.name}
                gridName={gridName}
                displayNameKey={row.displayNameKey}
                sort={sort && sort.name === row.name ? sort.desc : null}
                setSort={setSort}
                type={row.filterType}
                value={filters.filter && filters.filter.filter && filters.filter.filter[row.name]}
                filters={filters}
                setFilter={setFilter}
                source={row.source}
                notSortAndFilter={row.notSortAndFilter}
                noSort={row.noSort}
                notFilter={row.notFilter}
            />
        </th>
    );
};

export default React.memo(HeaderCellComponent);
