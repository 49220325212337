import React, {useRef, useEffect} from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const Facet = ({ name, value, onChange, displayNameKey }) => {
    const { t } = useTranslation();

    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            inputRef && inputRef.current && inputRef.current.focus();
        }, 100)
    }, []);

    return (
        <div className="facet-input">
            <Input
                ref={inputRef}
                fluid
                name={name}
                value={value || ''}
                autoComplete="off"
                placeholder={t(displayNameKey || name)}
                onChange={onChange}
            />
        </div>
    );
};
export default Facet;
