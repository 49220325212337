import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import { all, put, takeEvery } from 'redux-saga/effects';

//*  TYPES  *//
const GET_ADDITIONAL_SERVICES_REQUEST = 'GET_ADDITIONAL_SERVICES_REQUEST';
const GET_ADDITIONAL_SERVICES_SUCCESS = 'GET_ADDITIONAL_SERVICES_SUCCESS';
const GET_ADDITIONAL_SERVICES_ERROR = 'GET_ADDITIONAL_SERVICES_ERROR';

const GET_ADDITIONAL_SERVICE_TYPES_REQUEST = 'GET_ADDITIONAL_SERVICE_TYPES_REQUEST';
const GET_ADDITIONAL_SERVICE_TYPES_SUCCESS = 'GET_ADDITIONAL_SERVICE_TYPES_SUCCESS';
const GET_ADDITIONAL_SERVICE_TYPES_ERROR = 'GET_ADDITIONAL_SERVICE_TYPES_ERROR';

const CLEAR_ADDITIONAL_SERVICES = 'CLEAR_ADDITIONAL_SERVICES';
//*  INITIAL STATE  *//

const initial = {
    progress: false,
    uploadProgress: false,
    addProgress: false,
    additionalServiceTypes: [],
    additionalServices: [],
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_ADDITIONAL_SERVICES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_ADDITIONAL_SERVICES_SUCCESS:
            return {
                ...state,
                additionalServices: payload,
                progress: false,
            };
        case GET_ADDITIONAL_SERVICES_ERROR:
            return {
                ...state,
                progress: false,
                additionalServices: [],
            };
        case GET_ADDITIONAL_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                additionalServiceTypes: payload,
            };
        case CLEAR_ADDITIONAL_SERVICES:
            return {
                ...state,
                additionalServices: [],
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getAdditionalServiceTypesRequest = payload => {
    return {
        type: GET_ADDITIONAL_SERVICE_TYPES_REQUEST,
        payload,
    };
};

export const getAdditionalServicesRequest = payload => {
    return {
        type: GET_ADDITIONAL_SERVICES_REQUEST,
        payload,
    };
};

export const clearAdditionalServices = () => {
    return {
        type: CLEAR_ADDITIONAL_SERVICES,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.additionalServices;

export const additionalServiceTypesSelector = createSelector(
    stateSelector,
    state =>
        state.additionalServiceTypes &&
        state.additionalServiceTypes.map(x => ({
            key: x.id,
            value: x.id,
            text: x.name,
        })),
);

export const additionalServicesSelector = createSelector(stateSelector, state => state.additionalServices);
export const progressSelector = createSelector(stateSelector, state => state.progress);

//*  SAGA  *//

function* getAdditionalServiceTypesSaga({ payload }) {
    try {
        const result = yield postman.post(`/additionalServiceTypes/search`, {filter: {}});

        yield put({
            type: GET_ADDITIONAL_SERVICE_TYPES_SUCCESS,
            payload: result.items,
        });
    } catch (e) {
        yield put({
            type: GET_ADDITIONAL_SERVICE_TYPES_ERROR,
            payload: e,
        });
    }
}

function* getAdditionalServicesSaga({ payload }) {
    try {
        const { gridName, cardId } = payload;
        const result = yield postman.get(`/${gridName}/${cardId}/additionalServices`);

        yield put({
            type: GET_ADDITIONAL_SERVICES_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_ADDITIONAL_SERVICES_ERROR,
            payload: e,
        });
    }
}


export function* saga() {
    yield all([
        takeEvery(GET_ADDITIONAL_SERVICE_TYPES_REQUEST, getAdditionalServiceTypesSaga),
        takeEvery(GET_ADDITIONAL_SERVICES_REQUEST, getAdditionalServicesSaga),
    ]);
}
