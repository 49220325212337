import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Checkbox, Confirm, Grid, Loader} from 'semantic-ui-react';
import CardLayout from '../../components/CardLayout';
import {DICTIONARY_LIST_LINK} from "../../router/links";
import {MULTISELECT_TYPE} from "../../constants/columnTypes";
import './style.scss';
import GridParams from "./components/gridParams";
import {getLookupRequest, progressSelector} from "../../ducks/lookup";
import {
    getNotificationsSettingsRequest,
    getNotificationsTypesRequest,
    getParamsSettingsRequest,
    noErrorsSelector,
    loadingSelector,
    nameCompanySelector,
    notificationSettingsSelector,
    notificationTypesSelector,
    paramsSelector,
    saveNotificationsSettingsRequest, saveSettingsRequest
} from "../../ducks/params";
import {userNameSelector} from "../../ducks/profile";
import {columnsSettingsSelector} from "../../ducks/gridList";
import {showModal} from "../../ducks/modal";

const Params = props => {
    const {
        match = {},
        history,
    } = props;
    const { params = {} } = match;
    const { id } = params;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [notChangeForm, setNotChangeForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [clickSave, setClickSave] = useState(false);
    const [confirmation, setConfirmation] = useState({open: false});
    const [form, setForm] = useState([]);

    const changeForm = (form) => {
        notChangeForm && setNotChangeForm(false);
        setForm(form);
    }

    const progress = useSelector(state => loadingSelector(state));
    const noErrors = useSelector(state => noErrorsSelector(state));
    const progressForSelect = useSelector(state => progressSelector(state));
    const notificationTypes = useSelector(state => notificationTypesSelector(state));
    const notificationSettingsData = useSelector(state => notificationSettingsSelector(state));
    const [notifications, setNotifications] = useState({});
    const userName = useSelector(state => userNameSelector(state));
    const name = useSelector(state => nameCompanySelector(state));
    const parameters = useSelector(state => paramsSelector(state));
    const columns = useSelector(state => columnsSettingsSelector(state, 'shippingsNotificationSetting'));

    useEffect(()=>{
        setForm([...parameters]);
    }, [parameters]);

    useEffect(()=>{
        setNotifications({...notificationSettingsData});
    }, [notificationSettingsData]);

    useEffect(()=>{
        setLoading(progress || progressForSelect);
    }, [progress, progressForSelect]);

    useEffect(() => {
        dispatch(getParamsSettingsRequest(id));
        dispatch(getNotificationsTypesRequest());
        dispatch(getNotificationsSettingsRequest(id));
        columns.filter(col => col.type === MULTISELECT_TYPE).map(col =>
            dispatch(
                getLookupRequest({
                    name: col.source,
                    isForm: true,
                }),
            ));
    }, []);

    const handleChange = useCallback((e, {name, checked}) => {
        if (notChangeForm) {
            setNotChangeForm(false);
        }
        setNotifications(prevState => {
            const notifs = prevState.notifications ? [...prevState.notifications] : [];
            const notif = {
                type: name,
                isSelect: checked
            }
            const newNotifIndex = notifs.findIndex(n => n.type===name);
            if(newNotifIndex!==-1) notifs[newNotifIndex] = notif;
            else notifs.push(notif);

            return {
                isEnable: prevState.isEnable,
                notifications: [...notifs]
            }
        });
    }, [notChangeForm]);

    const handleChangeAll = useCallback((e, {checked}) => {
        if (notChangeForm) {
            setNotChangeForm(false);
        }
        setNotifications(prevState => {
            return {
                ...prevState,
                isEnable: checked
            }
        });
    }, [notChangeForm]);

    const isChecked = (type) => {
        const notificationSetting = notifications.notifications && notifications.notifications.find(notif => notif.type===type);
        return notificationSetting ? notificationSetting.isSelect : false;
    }

    const confirmClose = () => {
        setConfirmation({open: false});
    };

    const onClose = () => {
        setForm({});
        setClickSave(false);
        history.push(DICTIONARY_LIST_LINK.replace(':name', 'transportCompanies'));
    };
    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const onSend = () =>{
        setClickSave(true);
        dispatch(saveNotificationsSettingsRequest({
            ...notifications,
            transportCompanyId: id
        }));
        dispatch(saveSettingsRequest(form.map(row=>row.isNew ? {...row, id: null, names: null} : row)));
    }

    useEffect(()=>{
        const isDone = clickSave && !progress && noErrors;
        isDone && dispatch(showModal({
            message: t('displayOptionsSaved'),
            text: t('changesWillBeAppliedWithin5m'),
            callbackSuccess: onClose
        }))
    }, [progress, noErrors, clickSave]);

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue" disabled={notChangeForm} onClick={onSend}>
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, notChangeForm, notifications],
    );

    const getGridParams = useCallback(
        () => {
            return (
                <div className='params-notifications__grid'>
                    <GridParams
                        columns={columns}
                        form={form}
                        onChangeForm = {changeForm}
                        id={id}
                        nameTC={name}
                    />
                </div>
            );
        },
        [form, id, columns, name],
    );

    return (
        <div className='params-notifications'>
            <Loader active={loading} size="huge">
                Loading
            </Loader>
            <CardLayout
                title={`${t('settingNotificationsAndParametersShippings')} ${!loading ? (id ? name : userName) : ''}: ${t('edit_record')}`}
                actionsFooter={getActionsFooter}
                onClose={handleClose}
            >
                <Grid className='params-notifications__block params-notifications__block_fixed'>
                    <Checkbox
                        toggle
                        label={t('notifications')}
                        name={'isEnable'}
                        onChange={handleChangeAll}
                        checked={notifications.isEnable}
                    />
                    {
                        notifications.isEnable &&
                        <Grid.Row>
                            {
                                notificationTypes.map(notif => (
                                    <Checkbox
                                        key={notif.name}
                                        label={t(notif.name)}
                                        name={notif.name}
                                        onChange={handleChange}
                                        checked={isChecked(notif.name)}
                                    />
                                ))
                            }
                        </Grid.Row>
                    }
                </Grid>

                {
                    getGridParams()
                }

                <Confirm
                    dimmer="blurring"
                    open={confirmation.open}
                    onCancel={confirmation.onCancel}
                    cancelButton={t('cancelConfirm')}
                    confirmButton={t('Yes')}
                    onConfirm={confirmation.onConfirm}
                    content={confirmation.content}
                />
            </CardLayout>
        </div>

    );
};

export default Params;
