import React, {useEffect, useRef, useState} from 'react';
import {Button, Confirm, Form, Grid} from 'semantic-ui-react';
import FormField from '../formField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { SHIPPINGS_GRID } from '../../../../constants/grids';
import {
    auctionSavePriceRequest,
    startAutoUpdateAuctionInfo,
    stopAutoUpdateAuctionInfo
} from '../../../../ducks/gridCard';
import IdleTimer from "react-idle-timer";
import {toast} from "react-toastify";

const AuctionPriceOffer = ({ form = {}, onChange, settings, error, saveForm, load }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const idleTimerRef = useRef(null);

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID));

    const handleSubmit = () => {
        dispatch(auctionSavePriceRequest({
            id: form.id,
            params: {
                transportCompanyAuctionPriceOffer: form.transportCompanyAuctionPriceOffer,
                shippingVersion: form.rowVersion
            },
            callbackSuccess: load
        }));
        setConfirmationOpen(false);
    };

    const minAuctionPriceOffer = 1000;
    const onClick = () => {
        if (form.transportCompanyAuctionPriceOffer >= minAuctionPriceOffer) setConfirmationOpen(true);
        else toast.error(t('auctionPriceOfferIncorrect1000'));
    }

    useEffect(() => {
        _startUpdateAuctionInfo();

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            _stopUpdateAuctionInfo();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    }, []);

    const _startUpdateAuctionInfo = () => {
        if (form.id && form.status === 'auctionOpened') {
            dispatch(startAutoUpdateAuctionInfo({
                id: form.id,
                callbackSuccess: result => {
                    Object.keys(result).forEach(key => {
                        onChange(null, {
                            name: key,
                            value: result[key],
                        })
                    })
                }
            }));
        }
    };

    const _stopUpdateAuctionInfo = () => {
        if (form.id && form.status === 'auctionOpened') {
            dispatch(stopAutoUpdateAuctionInfo())
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            _stopUpdateAuctionInfo();
        } else {
            _startUpdateAuctionInfo();
        }
    };

    const onActive = e => {
        _startUpdateAuctionInfo();
    };

    const onIdle = e => {
        _stopUpdateAuctionInfo();
    };

    return (
        <Form className="tabs-card">
            <IdleTimer
                ref={idleTimerRef}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={1000 * 60 * 5}
            />
            <Grid>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <FormField
                            name="transportCompanyAuctionPriceOffer"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            numberClasses
                            error={error}
                            hintInfo={form.transportCompanyAuctionPriceOffer !== null ? form.transportCompanyAuctionPriceOfferHint : null}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="bestAuctionPriceOffer"
                            isReadOnly
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="auctionStartingPrice"
                            isReadOnly
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="auctionStep"
                            isReadOnly
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column verticalAlign="bottom">
                        <Button
                            primary
                            disabled={!form['isSendPriceOfferButtonEnabled']}
                            onClick={onClick}>
                                {t('sendPriceOffer')}
                        </Button>
                    </Grid.Column>

                    <Grid.Column>
                        <FormField
                            name="auctionFinishDateTime"
                            isReadOnly
                            columnsConfig={columnsConfig}
                            form={form}
                            settings={settings}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="auctionType"
                            isReadOnly
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Confirm
                open={confirmationOpen}
                content={<div className='content'>{t('auction-price-offer-confirmation-text')} <b>{(form.transportCompanyAuctionPriceOffer || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>?</div>}
                onCancel={()=>setConfirmationOpen(false)}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={handleSubmit}
            />
        </Form>
    );
};

export default AuctionPriceOffer;
