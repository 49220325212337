import React from 'react';
import {useTranslation} from 'react-i18next';
import CardLayout from '../../../components/CardLayout';
import {useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../ducks/representations";
import {isReadOnlyFieldsSelector} from "../../../ducks/gridList";
import {Grid} from "semantic-ui-react";
import FormField from "./formField";

const Content = ({error, columnsArray, form, handleChange, columnsConfig, settings, dictionaryName}) => {
    const isReadOnlyFields = useSelector(state => isReadOnlyFieldsSelector(state, dictionaryName));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <div className="ui form dictionary-edit">
                        {
                            columnsArray.map(c => {
                                return (
                                    <FormField
                                        key={c}
                                        isReadOnly={(isReadOnlyFields[c] && form[isReadOnlyFields[c]]) || columnsConfig[c].isReadOnly}
                                        name={c}
                                        columnsConfig={columnsConfig}
                                        form={form}
                                        error={error}
                                        onChange={handleChange}
                                        settings={settings}
                                    />
                                )
                            })
                        }
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const FreeTransportCard = ({
                               form,
                               onChangeForm,
                               name,
                               settings,
                               title,
                               actionsFooter,
                               onClose,
                               actionsHeader,
                               loading,
                               error,
                           }) => {
    const {t} = useTranslation();
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, name));
    const columnsArray = ['fromWhereZoneId', 'whereToZoneId', 'possibleLoadingPeriod',
        'vehicleTypeId', 'transportCount', 'isOwnTransport', 'transportTotalCostWithoutVAT',
        'transportTotalCostWithVAT', 'bargain', 'contactPhoneNumber', 'comment'];

    const getPanes = () => {
        const obj = [
            {
                menuItem: t('information'),
                render: () => (
                    <Content
                        form={form}
                        error={error}
                        settings={settings}
                        columnsConfig={columnsConfig}
                        dictionaryName={name}
                        handleChange={onChangeForm}
                        columnsArray={columnsArray}
                    />
                ),
            },
        ];

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            onClose={onClose}
            loading={loading}
        >
            {getPanes()[0].render()}
        </CardLayout>
    );
};

export default FreeTransportCard;